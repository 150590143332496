<template>
    <div class="col" style="color: #5e5e5e; font-size: 1.1rem; text-align: center; min-width: 600px">
        <div class="row" style="color: #7f7f7f;">
            <div class="col-sm-2 metricCol" title="Общее количество публикаций">Публикаций</div>
            <div class="col-sm-2 metricCol" v-if="metricData.autors != null" title="Количество уникальных авторов">Авторов</div>
            <div class="col-sm-2 metricCol" title="Сумма комментариев, лайков, репостов публикаций">Вовлеченность</div>
            <div class="col-sm-2 metricCol" title="Отношение количеста позитивных публикаций к негативным" style="min-width: 150px; margin-left: 20px">Лояльность</div>
        </div>
        <div class="row metricNum">
            <div class="col-sm-2 metricCol" title="Общее количество публикаций">{{ metricData.all }}</div>
            <div class="col-sm-2 metricCol" v-if="metricData.autors != null" title="Количество уникальных авторов">
                {{ metricData.autors }}
            </div>
            <div class="col-sm-2 metricCol" title="Сумма комментариев, лайков, репостов публикаций">{{ metricData.involvemed }}</div>
            <div class="col-sm-2 metricCol" title="Отношение количеста позитивных публикаций к негативным" style="min-width: 150px; margin-left: 20px">{{ metricData.loyalty }}</div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .metricNum {
        color: #3b7cb7;
        font-weight: bold;
    }
    .metricCol {
      min-width: 120px;
    }
    </style>

<script lang="js">
import {Vue, Prop, Component} from 'vue-property-decorator'

  @Component({
    name: 'c-socMediaMetric'
  })
export default class СSocMediaMetric extends Vue {
    @Prop({
      required: true,
      default: { all: 0, autors: null, involvemed: 0, loyalty: 0 }
    })
    metricData
  }
</script>
