<template>
    <div>
        <b-tabs card v-model="tabIndex">
            <b-tab title="Социальные сети">
                <template v-on="tabIndex==0">
                    <c-soc-media :type="1"></c-soc-media>
                </template>
            </b-tab>
            <b-tab title="Масс-медиа">
                <template v-on="tabIndex==1">
                    <c-soc-media :type="2"></c-soc-media>
                </template>
            </b-tab>
        </b-tabs>
    </div>
</template>>

<script lang="js">
import { Vue, Component } from 'vue-property-decorator'

import bootstrap from 'bootstrap-vue'
import cSocMedia from '@/modules/socialnet/components/cSocMedia.vue'

Vue.use(bootstrap)

@Component({
  components: {
    'c-soc-media': cSocMedia
  }
})
export default class SocialMedia extends Vue {
   tabIndex = 0;
}

</script>
