<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import { Chart } from 'highcharts-vue';
highchartsMore(Highcharts);
@Component({
    name: 'd-sphereTonesSpider',
    components: {
        highcharts: Chart
    }
})
export default class DSphereTonesSpider extends Vue {
    @Prop({
        required: false,
        default: null
    })
    theme;

    @Prop({
        required: true,
        default: {}
    })
    period;

    @Prop({
        required: true,
        default: []
    })
    datas;

    @Prop({
        required: false,
        default: []
    })
    unrelevants;

    @Prop({
        required: false,
        default: []
    })
    spheres;

    chartOptions = {};

    created() {
        this.$watch('datas', () => {
            this.updateOptions();
        });
    }

    mounted() {
        this.updateOptions();
    }

    updateOptions() {
        const colors = ['#1f4e79', '#f8cbad', '#bfbfbf', '#3b7cb7', '#e37979', '#bdd7ee'];
        const categories = new Set();
        const mapSeries = new Map();
        let datas = {};
        const series = [];
        const total = [];
        const negative = [];
        const neutral = [];
        const positive = [];

        this.datas.forEach((item) => {
            if (this.unrelevants.indexOf(item.id) === -1) {
                for (const sph of item.spheres) {
                    datas = this.mapValue(mapSeries, sph);

                    switch (item.tone) {
                        case -2:
                            datas.neg++;
                            break;
                        case 1:
                            datas.neut++;
                            break;
                        case 2:
                            datas.pos++;
                            break;
                        default:
                            break;
                    }
                    datas.tot++;
                    mapSeries.set(sph, datas);
                }
            }
        });

        const entries = Array.from(mapSeries, ([name, value]) => ({ name, value }));
        const sortMap = entries.sort(this.sortDownByField('value'));

        sortMap.forEach((item) => {
            for (const sph of this.spheres) {
                if (sph.value === item.name) {
                    categories.add(sph.text);
                }
            }
            total.push(item.value.tot);
            positive.push(item.value.pos);
            neutral.push(item.value.neut);
            negative.push(item.value.neg);
        });
        series.push({ name: 'Всего', data: total, pointPlacement: 'on' });
        series.push({ name: 'Негативная', data: negative, pointPlacement: 'on' });
        series.push({ name: 'Нейтральная', data: neutral, pointPlacement: 'on' });
        series.push({ name: 'Позитивная', data: positive, pointPlacement: 'on' });

        this.chartOptions = {
            colors,
            chart: {
                polar: true,
                type: 'area',
                height: 500
            },

            exporting: {
                enabled: false,
                buttons: {
                    enabled: false
                }
            },

            title: {
                text: (this.$route.name === 'Topic' ? 'Публикации по сферам жизнедеятельности' : ''),
                style: {
                    color: '#5e5e5e',
                    fontSize: '1rem'
                }
            },

            pane: {
                size: '75%'
            },

            xAxis: {
                categories: Array.from(categories),
                tickmarkPlacement: 'on',
                lineWidth: 0,
                labels: {
                    align: 'center',
                    distance: 50
                }
            },

            yAxis: {
                gridLineInterpolation: 'polygon',
                lineWidth: 0,
                min: 0,
                endOnTick: true,
                showLastLabel: true
            },

            tooltip: {
                shared: true,
                headerFormat: '<span style="font-size: 12px">{point.key}:</span>&nbsp;&nbsp;<b>{point.y:,.2f}</b>',
                pointFormat: '',
                useHTML: true
            },
            legend: {
                itemStyle: {
                    color: '#5e5e5e',
                    fontSize: '.9rem',
                    fontWeight: 'normal',
                    marginTop: '2rem'
                }
            },
            series: series,
            credits: {
                enabled: false
            }
        };
    }

    mapValue(map, autor) {
        let datas = { 'tot': 0, 'neg': 0, 'neut': 0, 'pos': 0 };
        if (map.size === 0) {
            map = new Map();
            map.set(autor, datas);
        } else {
            if (map.get(autor) === undefined) {
                map.set(autor, datas);
            } else {
                datas = map.get(autor);
            }
        }
        return datas;
    }

    sortDownByField(field) {
        return (a, b) => (a[field].tot < b[field].tot ? 1 : -1);
    }
}
</script>