<template>
    <div class="soc-table">
        <template v-if="info!=null">
            <label>
                Всего:
                <span class="toneTot">{{info.all}}</span>
            </label>
            <label>
                Позитивная:
                <span class="tonePos">{{info.pos}}</span>
            </label>
            <label>
                Негативная:
                <span class="toneNeg">{{info.neg}}</span>
            </label>
            <label>
                Нейтральная:
                <span class="toneNeut">{{info.neut}}</span>
            </label>
        </template>
        <div v-if="unrelevFl" style="text-align: right">
            <b-dropdown size="lg" variant="outline-primary" toggle-class="text-decoration-none" no-caret :disabled="!isChecked">
                <template v-slot:button-content>
                    <div class="add-lenta"></div>
                </template>
                <b-dropdown-item @click="unrelRecov()">Восстановить</b-dropdown-item>
            </b-dropdown>
        </div>
        <div style="display: table; width: 100%;">
            <div style="display:table-header-group;">
                <div class="socialrow row-header">
                    <div class="socialCell">
                        <b-form-checkbox v-model="checkAll" @input="chgCheckAll"></b-form-checkbox>
                    </div>
                    <!--<div class="socialCell" v-if="unrelevFl"></div>-->
                    <div
                        class="socialCell socialCellHead"
                        @click="clkSort('socialNet')"
                        v-if="type==1"
                    >
                        <template v-if="sortField=='socialNet'">
                            <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
                            <span class="mdi mdi-arrow-down" v-else></span>
                        </template>
                    </div>
                    <div class="socialCell socialCellHead" v-else></div>
                    <div class="socialCell socialCellHead" @click="clkSort('text')" style="width: 200px;">
                        Публикация
                        <template v-if="sortField=='text'">
                            <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
                            <span class="mdi mdi-arrow-down" v-else></span>
                        </template>
                    </div>
                    <div
                        class="socialCell socialCellHead"
                        @click="clkSort('smi')"
                        v-if="type==2"
                        style="min-width: 60px"
                    >
                        Сми
                        <template v-if="sortField=='smi'">
                            <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
                            <span class="mdi mdi-arrow-down" v-else></span>
                        </template>
                    </div>
                    <div
                        class="socialCell socialCellHead"
                        @click="clkSort('eventName')"
                        style="min-width: 90px"
                    >
                        Событие
                        <template v-if="sortField=='eventName'">
                            <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
                            <span class="mdi mdi-arrow-down" v-else></span>
                        </template>
                    </div>
                    <div
                        class="socialCell socialCellHead"
                        @click="clkSort('tone')"
                        style="min-width: 50px"
                    >
                        Тон
                        <template v-if="sortField=='tone'">
                            <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
                            <span class="mdi mdi-arrow-down" v-else></span>
                        </template>
                    </div>
                    <div class="socialCell socialCellHead" @click="clkSort('bDate')">
                        Дата
                        <template v-if="sortField=='bDate'">
                            <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
                            <span class="mdi mdi-arrow-down" v-else></span>
                        </template>
                    </div>
                    <div
                        class="socialCell socialCellHead"
                        @click="clkSort('audienceSize')"
                        v-if="false"
                    >
                        Охват аудиторией
                        <template v-if="sortField=='audienceSize'">
                            <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
                            <span class="mdi mdi-arrow-down" v-else></span>
                        </template>
                    </div>
                    <div
                        class="socialCell socialCellHead"
                        @click="clkSort('likeSum')"
                        style="min-width: 45px; padding-right: 5px"
                    >
                        <span class="mdi mdi-thumb-up-outline" title="Лайки"></span>
                        <template v-if="sortField=='likeSum'">
                            <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
                            <span class="mdi mdi-arrow-down" v-else></span>
                        </template>
                    </div>
                    <div
                        class="socialCell socialCellHead"
                        @click="clkSort('commentSum')"
                        style="min-width: 45px; padding-right: 5px"
                    >
                        <span class="mdi mdi-comment-outline" title="Комментарии"></span>
                        <template v-if="sortField=='commentSum'">
                            <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
                            <span class="mdi mdi-arrow-down" v-else></span>
                        </template>
                    </div>
                    <div
                        class="socialCell socialCellHead"
                        @click="clkSort('viewSum')"
                        style="min-width: 45px; padding-right: 5px"
                    >
                        <span class="mdi mdi-eye-outline" title="Просмотры"></span>
                        <template v-if="sortField=='viewSum'">
                            <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
                            <span class="mdi mdi-arrow-down" v-else></span>
                        </template>
                    </div>
                    <div
                        class="socialCell socialCellHead"
                        @click="clkSort('autor')"
                        v-if="type==1"
                        style="min-width: 65px"
                    >
                        Автор
                        <template v-if="sortField=='autor'">
                            <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
                            <span class="mdi mdi-arrow-down" v-else></span>
                        </template>
                    </div>
                </div>
            </div>
            <div style="display: table-row-group;">
                <div
                    class="socialrow"
                    v-for="(dt, dt_indx) in tabDataToPage"
                    :key="'dt_indx'+dt_indx"
                >
                    <div class="socialCell">
                        <template v-if="dt_indx<chkArr.length">
                            <b-checkbox
                                v-model="chkArr[dt_indx].checked"
                                @input="chgCheck(chkArr[dt_indx].checked)"
                            ></b-checkbox>
                        </template>
                    </div>
                   <!-- <div class="socialCell" v-if="unrelevFl">
                        <b-dropdown
                            size="lg"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                        >
                            <template v-slot:button-content>
                                <div class="add-lenta"></div>
                            </template>
                            <b-dropdown-item @click="unrelRecov(dt)">Восстановить</b-dropdown-item>
                        </b-dropdown>
                    </div> -->
                    <div class="socialCell">
                        <a :href="dt.url" target="_blank" rel="noopener noreferrer">
                            <c-socSrcIcon :source="dt.socialNet" v-if="type==1"></c-socSrcIcon>
                            <span
                                class="mdi mdi-web-box"
                                style="font-size: 1.5rem; margin-left: 1rem; color: #489ed7"
                                v-else
                            ></span>
                        </a>
                    </div>
                    <div
                        class="socialCell"
                        style="width: 40%; max-width: 40%; word-break: break-all;"
                    >
                        <div class="socialCaption" v-html="dt.caption"></div>
                        <div
                            v-if="moreIndx==dt_indx"
                            class="text"
                            @click="clkMoreClose(dt_indx)"
                            v-html="fullText"
                        ></div>
                        <template v-else>
                            <div class="text" v-html="dt.text"></div>
                            <div
                                class="more"
                                @click="getFullText(dt_indx)"
                                v-if="dt.textShort"
                            >Подробнее...</div>
                        </template>
                    </div>
                    <div class="socialCell" v-if="type==2">{{dt.smi}}</div>
                    <div class="socialCell" @click="eventClk(dt_indx)" style="cursor: pointer">
                        <template v-if="dt.eventName!=null">{{dt.eventName}}</template>
                    </div>
                    <div class="socialCell">
                        <b-dropdown
                            size="lg"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                        >
                            <template v-slot:button-content>
                                <span
                                    :class="{'mdi':true, 'mdi-circle': true, 'tonePos': dt.tone==2, 'toneNeg': dt.tone==-2, 'toneNeut': dt.tone==1, 'toneTot': dt.tone==-100}"
                                    :title="dt.toneText"
                                ></span>
                            </template>
                            <b-dropdown-item
                                v-for="t in tone"
                                :key="dt.id+t.value"
                                @click="setnewtone(dt, t.value)"
                            >
                                <span
                                    :class="{ 'tonePos': t.value==2, 'toneNeg': t.value==-2, 'toneNeut': t.value==1}"
                                >{{t.text}}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="socialCell" style="padding-right: 15px">{{dateTimeFormat().format(new Date(dt.bDate))}}</div>
                    <div class="socialCell" v-if="false">{{dt.audienceSize}}</div>
                    <div class="socialCell">
                        <template v-if="dt.likeSum!=null">{{dt.likeSum}}</template>
                    </div>
                    <div class="socialCell">{{dt.commentSum}}</div>
                    <div class="socialCell">{{dt.viewSum}}</div>
                    <div class="socialCell" v-if="type==1">{{dt.autor}}</div>
                </div>
            </div>
        </div>
        <div
            v-if="pgRows>perPage"
            style="width: 100%; display: flex; justify-content:center; align-items: center;"
        >
            <b-pagination
                v-model="curPage"
                :per-page="perPage"
                :total-rows="pgRows"
                :limit="7"
                @input="pageChg()"
            />
        </div>
        <hr />
        <template v-if="!unrelevFl">
            <div>
                <b-dropdown
                    size="lg"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    title="Редактировать"
                    :disabled="!isChecked"
                >
                    <template v-slot:button-content>
                        <b-badge>
                            <span class="mdi mdi-file-cog-outline"></span>
                        </b-badge>
                    </template>
                    <b-dropdown-item @click="eventClk()">Событие</b-dropdown-item>
                    <b-dropdown-item
                        @click="delUnrelevant()"
                        v-if="theme!=null"
                    >Удалить как нерелевантный</b-dropdown-item>
                </b-dropdown>
            </div>
            <b-modal v-model="eventModalShow" hide-footer title="Событие">
                <div class="d-block text-center">
                    <multiselect
                        v-model="eventSelect"
                        track-by="id"
                        label="name"
                        placeholder="Выбрать событие"
                        :options="eventLst"
                        :searchable="true"
                        :allow-empty="false"
                    >
                        <template slot="singleLabel" slot-scope="{ option }">
                            <strong>{{ option.name }}</strong>
                        </template>
                    </multiselect>
                </div>
                <br />
                <div style="text-align: right">
                    <b-button
                        style="min-width: 100px; margin-right: 25px"
                        @click="eventModalShow=false"
                    >Отмена</b-button>
                    <b-button
                        variant="outline-danger"
                        style="min-width: 100px"
                        @click="delEvent"
                    >Удалить</b-button>
                    <b-button variant="outline-primary" @click="saveEvent" :disabled="eventSelect == null">Сохранить</b-button>
                </div>
            </b-modal>
        </template>
    </div>
</template>

<script lang="js">
import {Vue, Prop, Component} from 'vue-property-decorator'
import CSocialSourceIcon from '@/modules/socialnet/components/cSocialSourceIcon.vue'
import Multiselect from 'vue-multiselect'
import axios from 'axios'


  @Component({
    name: 'c-socTab',
    components: {
      'c-socSrcIcon': CSocialSourceIcon,
      'multiselect': Multiselect
    }
  })
export default class СSocialTable extends Vue {
    @Prop({
      required: true,
      default: []
    })
    data;
    @Prop({
      required: false,
      default: {all: 0, pos: 0, neg: 0, neut: 0}
    })
    infoData;
    @Prop({
      required: true,
      default: 1
    })
    type;

    @Prop({
      required: false,
      default: null
    })
    theme

    @Prop({
      required: false,
      default: false
    })
    unrelevFl // true - в модальном окне

    @Prop({
      required: false,
      default: null
    })
    eventLst

    @Prop({
      required: false,
      default: null
    })
    filterEvent

    newtone
    // ----------------------------------- событие ----------------
    eventModalShow = false // показать/скрыть модальное окно по событиям
    eventSelect = null // выбранное событие в multiselect
    curItemForEvent = null // текущая запись при изменении события
    // ------------------------------------------------------------
    checkAll = false
    chkArr = []
    isChecked = false // есть ли отмеченные чекбоксы

    get keywordArr () { // получить массив ключевых слов
      if (this.theme == null) {
        return null
      }
      const arr = []
      let text = this.theme.keywords
      text = text.replace(new RegExp('[(]', 'g'), '')
      text = text.replace(new RegExp('[)]', 'g'), '')
      text = text.replace(new RegExp('[*]', 'g'), '')
      let bIndx = 0
      for (let i = 0; i < text.length; i++) {
        let str = null
        if (text[i] === '*' || text[i] === '+' || text[i] === '|') {
          str = text.substring(bIndx, i)
          str = str.trim()
        } else if (i === text.length - 1) {
          str = text.substring(bIndx).trim()
        }
        if (str != null) {
          str = str.toLowerCase()
          bIndx = i + 1
          arr.push(str)
        }
      }
      // убрать дубли
      for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) {
            delete arr[j]
          }
        }
      }
      return arr
    }

    get info () {
      return this.infoData
    }

    pgRows = 1;
    curPage = 1;
    perPage = 10;
    tone = [
      {text: 'Негативная', value: '-2'},
      {text: 'Нейтральная', value: '1'},
      {text: 'Позитивная', value: '2'}
    ];
    // ----------------сортировка
    sortField = null; // поле для сортировки
    sortIncrease = true; // сортировка по возрастанию - true
    // --------------
    moreIndx = -1; // индекс "подробнее"
    // ---------
    fullText = ''

    pageChg () {
      this.moreIndx = -1
    }

    get tabData () {
      // this.sortField = null
      // this.sortIncrease = true
      const data = this.data.slice()
      this.moreIndx = -1
      this.fullText = ''
      /* const strMaxLength = 70
       for (let i = 0; i < this.data.length; i++) {
        const d = this.data[i]
        if (d.text.length > strMaxLength) {
          data[i].textShort = d.text.substring(0, strMaxLength) + '...'
        }
      } */
      return data
    }

    get sortedFilteredData () {
      const that = this
      const data = this.tabData.slice()
      let result = []
      // --фильтр по событиям
      if (that.filterEvent !== null && that.filterEvent.id !== -1) {
        const newData = []
        for (const el of data) {
          if (el.eventId === that.filterEvent.id) {
            newData.push(el)
          }
        }
        result = newData
      } else {
        result = data
      }
      // -------------------
      that.curPage = 1
      that.pgRows = result.length

      if (this.sortField !== null) {
        const t = this.sortTabData
        if (this.sortIncrease) {
          result = result.sort(t[this.sortField].up)
        } else {
          result = result.sort(t[this.sortField].down)
        }
      }
      /* for (const el of data) {
        el.checked = false
      } */
      return result
    }

    // массив элементов с текущей страницы
    get tabDataToPage () {
      const that = this
      that.chkArr = []
      that.checkAll = false
      if (this.tabData === undefined) {
        return []
      }
      let begIndx = (that.curPage - 1) * that.perPage
      if (begIndx >= that.sortedFilteredData.length) {
        begIndx = that.sortedFilteredData.length - 1
      }
      const endIndx = begIndx + that.perPage
      let result = null
      if (endIndx >= that.sortedFilteredData.length) {
        result = that.sortedFilteredData.slice(begIndx)
      }
      result = that.sortedFilteredData.slice(begIndx, endIndx)
      let keywordArr = null
      if (that.theme == null) {
        if (that.filterEvent !== null && that.filterEvent.keywords !== undefined && that.filterEvent.keywords != null) {
          keywordArr = that.filterEvent.keywords.split(';')
          for (let i = 0; i < keywordArr.length; i++) {
            keywordArr[i] = keywordArr[i].trim()
            if (keywordArr[i] === '') {
              keywordArr.splice(i, 1)
              if (i > 0) { i-- }
            }
          }
        }
      } else {
        keywordArr = that.keywordArr
      }
      for (const el of result) {
        el.text = that.highlightTxt(el.text, keywordArr)
        if (el.caption !== null && el.caption !== undefined) {
          el.caption = that.highlightTxt(el.caption, keywordArr)
        }
        that.chkArr.push({checked: false})
      }
      return result
    }

    highlightTxt (text, keywordArr) { // выделение текста по ключевым словам
      if (keywordArr == null) {
        return text
      }
      if (text == null || text.length < 0) {
        return ''
      }
      let resArr = [{text: text + ''}]
      for (const el of keywordArr) {
        const arr = []
        for (const elRes of resArr) {
          let bIndx = 0
          const lowTxt = elRes.text.toLowerCase()
          let index = lowTxt.indexOf(el)
          if (!elRes.highlight && index >= 0) {
            while (index >= 0) {
              if (index > 0) {
                arr.push({text: elRes.text.substring(bIndx, index)})
              }
              arr.push({text: elRes.text.substring(index, index + el.length), highlight: true})
              bIndx = index + el.length
              index = lowTxt.indexOf(el, bIndx)
            }
            if (bIndx < text.length - 1) {
              arr.push({text: elRes.text.substring(bIndx)})
            }
          } else {
            arr.push(elRes)
          }
        }
        resArr = arr
      }
      let resTxt = ''
      for (const el of resArr) {
        if (el.highlight) {
          resTxt = resTxt + '<span style="background-color: #ffe000">' + el.text + '</span>'
        } else {
          resTxt = resTxt + el.text
        }
      }
      return resTxt
    }

    dateTimeFormat () {
      return new Intl.DateTimeFormat('ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      })
    }

    sortTabData = {
      caption: {
        up: function (d1, d2) {
          return (d1.caption.toLowerCase() > d2.caption.toLowerCase()) ? 1 : -1
        },
        down: function (d1, d2) {
          return (d1.caption.toLowerCase() < d2.caption.toLowerCase()) ? 1 : -1
        }
      },
      text: {
        up: function (d1, d2) {
          return (d1.text.toLowerCase() > d2.text.toLowerCase()) ? 1 : -1
        },
        down: function (d1, d2) {
          return (d1.text.toLowerCase() < d2.text.toLowerCase()) ? 1 : -1
        }
      },
      bDate: {
        up: function (d1, d2) {
          return (d1.bDate - d2.bDate)
        },
        down: function (d1, d2) {
          return (d2.bDate - d1.bDate)
        }
      },
      audienceSize: {
        up: function (d1, d2) {
          return (d1.audienceSize - d2.audienceSize)
        },
        down: function (d1, d2) {
          return (d2.audienceSize - d1.audienceSize)
        }
      },
      likeSum: {
        up: function (d1, d2) {
          return (d1.likeSum - d2.likeSum)
        },
        down: function (d1, d2) {
          return (d2.likeSum - d1.likeSum)
        }
      },
      commentSum: {
        up: function (d1, d2) {
          return (d1.commentSum - d2.commentSum)
        },
        down: function (d1, d2) {
          return (d2.commentSum - d1.commentSum)
        }
      },
      viewSum: {
        up: function (d1, d2) {
          return (d1.viewSum - d2.viewSum)
        },
        down: function (d1, d2) {
          return (d2.viewSum - d1.viewSum)
        }
      },
      autor: {
        up: function (d1, d2) {
          return (d1.autor.toLowerCase() > d2.autor.toLowerCase()) ? 1 : -1
        },
        down: function (d1, d2) {
          return (d1.autor.toLowerCase() < d2.autor.toLowerCase()) ? 1 : -1
        }
      },
      socialNet: {
        up: function (d1, d2) {
          return (d1.socialNet - d2.socialNet)
        },
        down: function (d1, d2) {
          return (d2.socialNet - d1.socialNet)
        }
      },
      tone: {
        up: function (d1, d2) {
          return (d1.tone - d2.tone)
        },
        down: function (d1, d2) {
          return (d2.tone - d1.tone)
        }
      },
      eventName: {
        up: function (d1, d2) {
          return (d1.eventName.toLowerCase() > d2.eventName.toLowerCase()) ? 1 : -1
        },
        down: function (d1, d2) {
          return (d1.eventName.toLowerCase() < d2.eventName.toLowerCase()) ? 1 : -1
        }
      },
      smi: {
        up: function (d1, d2) {
          return (d1.smi.toLowerCase() > d2.smi.toLowerCase()) ? 1 : -1
        },
        down: function (d1, d2) {
          return (d1.smi.toLowerCase() < d2.smi.toLowerCase()) ? 1 : -1
        }
      }
    };

    clkSort (field) { // нажать на кнопку сортировать
      if (this.sortField === field) {
        this.sortIncrease = !this.sortIncrease
        return
      }
      this.sortField = field
    }

    clkMoreClose (indx) {
      if (indx === this.moreIndx) {
        this.fullText = ''
        this.moreIndx = -1
      }
    }

    async getFullText (indx) {
      const that = this
      that.fullText = ''
      let el = null
      await axios
        .post('/report-form-fullText', {
          params: {
            'type': that.type,
            'id': that.tabDataToPage[indx].id,
            'socialNet': that.tabDataToPage[indx].socialNet
          }
        })
        .then(response => (el = response.data))
      let keywordArr = null
      if (that.theme == null) {
        if (that.filterEvent !== null && that.filterEvent.keywords !== undefined && that.filterEvent.keywords != null) {
          keywordArr = that.filterEvent.keywords.split(';')
          for (let i = 0; i < keywordArr.length; i++) {
            keywordArr[i] = keywordArr[i].trim()
            if (keywordArr[i] === '') {
              keywordArr.splice(i, 1)
              if (i > 0) { i-- }
            }
          }
        }
      } else {
        keywordArr = that.keywordArr
      }
      that.fullText = that.highlightTxt(el.data.text, keywordArr)
      this.moreIndx = indx
    }

    delUnrelevant (item) {
      const that = this
      let result = null
      if (item != null && item !== undefined) {
        result = [item]
      } else {
        result = that.findChecked()
      }
      that.$emit('delUnrelevant', result)
    }

    setnewtone (item, tone) {
      console.log(item, tone)
      item.tone = tone
      const topicid = this.theme.id
      const that = this
      axios.post(
        'set-new-tone',
        {
          params: {
            'type': that.type,
            'post_id': item.id,
            'topic_id': topicid,
            'smi': item.smi,
            'socialNet': item.socialNet,
            'tone': item.tone
          }
        }
      ).then(response => (console.log(response.data)))
    }

    unrelRecov (item) { // восстановление нерелевантных данных
      let items = null
      if (item != null && item !== undefined) {
        items = [item]
      } else {
        items = this.findChecked()
      }
      this.$emit('recover', items)
    }

    eventClk (index) { // открытие модального окна
      const that = this
      that.eventSelect = null
      that.curItemForEvent = []
      if (index !== null && index !== undefined) {
        const obj = Object.assign({}, that.tabDataToPage[index])
        for (let i = 0; i < that.chkArr.length; i++) {
          that.chkArr[i].checked = (i === index)
        }
        that.curItemForEvent.push(obj)
        if (that.unrelevFl) { return }
        that.eventSelect = null
        if (obj.eventId !== null) {
          for (let i = 0; i < that.eventLst.length; i++) {
            if (that.eventLst[i].id === obj.eventId) {
              that.eventSelect = Object.assign({}, that.eventLst[i])
              break
            }
          }
        }
      } else {
        that.curItemForEvent = that.findChecked()
      }
      that.eventModalShow = true
    }

    saveEvent () { // сохранение события
      this.$emit('saveEvent', {items: this.curItemForEvent, event: this.eventSelect})
      this.checkAll = false
      this.chgCheckAll()
      this.eventModalShow = false
    }

    delEvent () { // удаление события
      this.$emit('saveEvent', {items: this.curItemForEvent, event: null})
      this.checkAll = false
      this.chgCheckAll()
      this.eventModalShow = false
    }

    chgCheckAll () { // изменение checkAll
      const that = this
      for (const el of that.chkArr) {
        el.checked = that.checkAll
      }
    }

    chgCheck (checked) { // изменение чекбокса
      const that = this
      if (checked) {
        that.isChecked = true
        return
      }
      let fl = false
      for (let i = 0; i < that.chkArr.length; i++) {
        if (that.chkArr[i].checked) {
          fl = true
          break
        }
      }
      that.isChecked = fl
    }

    findChecked () { // найти все отмеченные
      const that = this
      const items = []
      for (let i = 0; i < that.chkArr.length; i++) {
        const el = that.chkArr[i]
        if (el.checked) {
          items.push(Object.assign({}, that.tabDataToPage[i]))
        }
      }
      return items
    }
  }

</script>
