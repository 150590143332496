<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';

@Component({
    name: 'd-netsBar',
    components: {
        highcharts: Chart
    }
})
export default class DSocialNetsBar extends Vue {
    @Prop({
        required: false,
        default: null
    })
    section;

    @Prop({
        required: false,
        default: null
    })
    datas;

    @Prop({
        required: false,
        default: []
    })
    unrelevants;

    chartOptions = {};

    created() {
        this.$watch('datas', () => {
            this.updateOptions();
        });
    }

    mounted() {
        this.updateOptions();
    }

    updateOptions() {
        const colors = ['#f8cbad', '#bfbfbf', '#3b7cb7', '#e37979', '#bdd7ee'];
        const series = [];
        const categories = [];

        const total = [];
        const instagram = [];
        const facebook = [];
        const vkontakte = [];
        const youtube = [];
        const telegram = [];

        this.datas.forEach((item) => {
            if (item.datas.data.length > 0) {
                categories.push(item.theme.name);

                let tot = 0;
                let ig = 0;
                let fb = 0;
                let vk = 0;
                let yt = 0;
                let tg = 0;
                item.datas.data.forEach((post) => {
                    if (this.unrelevants.indexOf(post.id) === -1) {
                        switch (post.socialNet) {
                            case 1:
                                ig++;
                                break;
                            case 2:
                                fb++;
                                break;
                            case 3:
                                vk++;
                                break;
                            case 4:
                                yt++;
                                break;
                            case 5:
                                tg++;
                                break;
                            default:
                                break;
                        }
                        tot++;
                    }
                });
                total.push(tot);
                instagram.push(ig);
                facebook.push(fb);
                vkontakte.push(vk);
                youtube.push(yt);
                telegram.push(tg);
            }
        });
        // series.push({name: 'Всего', data: total})
        series.push({ name: 'instagram', data: instagram });
        series.push({ name: 'facebook', data: facebook });
        series.push({ name: 'vkontakte', data: vkontakte });
        series.push({ name: 'youtube', data: youtube });
        series.push({ name: 'telegram', data: telegram });

        this.chartOptions = {
            colors,
            chart: {
                type: 'bar'
            },
            title: {
                text: (this.section.section !== null ? 'Раздел "' + this.section.section.name + '"' : ''),
                style: {
                    color: '#5e5e5e',
                    fontSize: '1rem'
                }
            },
            xAxis: {
                categories: categories,
                labels: {
                    style: {
                        fontSize: '1rem',
                        color: '#5e5e5e'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        backgroundColor: '#fff',
                        borderColor: '#f2f2f2',
                        borderWidth: '1px',
                        formatter() {
                            return (this.y > 0 ? '<span style="color: ' + this.point.color + '">' + this.y + '</span>' : '');
                        },
                        style: {
                            fontSize: '.8rem',
                            fontWeight: 'bold',
                            color: '#fff',
                            textOutline: '0'
                        }
                    }
                },
                series: {
                    stacking: 'normal',
                    pointWidth: 20
                }
            },
            legend: {
                itemStyle: {
                    color: '#5e5e5e',
                    fontSize: '.9rem',
                    fontWeight: 'normal'
                }
            },
            series: series,
            credits: {
                enabled: false
            }
        };
    }
}
</script>