<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';

@Component({
    name: 'd-toneColumn',
    components: {
        highcharts: Chart
    }
})
export default class DMediaToneColumn extends Vue {
    @Prop({
        required: false,
        default: {}
    })
    theme;

    @Prop({
        required: false,
        default: null
    })
    period;

    @Prop({
        required: true,
        default: []
    })
    datas;

    @Prop({
        required: false,
        default: []
    })
    unrelevants;

    chartOptions = {};

    created() {
        this.$watch('datas', () => {
            this.updateOptions();
        });
    }

    mounted() {
        this.updateOptions();
    }

    updateOptions() {
        const colors = ['#f8cbad', '#bfbfbf', '#3b7cb7', '#e37979', '#bdd7ee'];
        const categories = new Set();
        const mapSeries = new Map();
        let sortMap = new Map();
        let values = {};
        const series = [];
        // let total = []
        const negative = [];
        const neutral = [];
        const positive = [];

        this.datas.forEach((item) => {
            if (this.unrelevants.indexOf(item.id) === -1) {
                values = this.mapValue(mapSeries, item.lang);

                switch (item.tone) {
                    case -2:
                        values.neg++;
                        break;
                    case 1:
                        values.neut++;
                        break;
                    case 2:
                        values.pos++;
                        break;
                }
                values.tot++;
                mapSeries.set(item.lang, values);
            }
        });

        sortMap = this.sortedMap(mapSeries);
        for (const m of sortMap.keys()) {
            switch (m) {
                case 'kz':
                    categories.add('Казахский');
                    break;
                case 'ru':
                    categories.add('Русский');
                    break;
                default:
                    break;
            }
            negative.push(sortMap.get(m).neg);
            neutral.push(sortMap.get(m).neut);
            positive.push(sortMap.get(m).pos);
        }

        categories.add('Всего');
        let neg = 0;
        let neut = 0;
        let pos = 0;
        for (const s of sortMap.values()) {
            neg += s.neg;
            neut += s.neut;
            pos += s.pos;
        }
        negative.push(neg);
        neutral.push(neut);
        positive.push(pos);

        series.push({ name: 'Негативная', data: negative });
        series.push({ name: 'Нейтральная', data: neutral });
        series.push({ name: 'Позитивная', data: positive });

        this.chartOptions = {
            colors,
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: Array.from(categories)
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        backgroundColor: '#fff',
                        borderColor: '#f2f2f2',
                        borderWidth: '1px',
                        formatter() {
                            return (this.y > 0 ? '<span style="color: ' + this.point.color + '">' + this.y + '</span>' : '');
                        },
                        style: {
                            fontSize: '.8rem',
                            fontWeight: 'bold',
                            color: '#fff',
                            textOutline: '0'
                        }
                    }
                },
                series: {
                    stacking: 'normal',
                    pointWidth: 60
                }
            },
            series: series,
            credits: {
                enabled: false
            }
        };
    }

    mapValue(map, autor) {
        let datas = { 'tot': 0, 'neg': 0, 'neut': 0, 'pos': 0 };
        if (map.size === 0) {
            map = new Map();
            map.set(autor, datas);
        } else {
            if (map.get(autor) === undefined) {
                map.set(autor, datas);
            } else {
                datas = map.get(autor);
            }
        }
        return datas;
    }

    sortedMap(map) {
        const sort = new Map();
        while (map.size > 0) {
            let max = -1;
            let key = '';
            for (const k of map.keys()) {
                if (map.get(k).tot > max) {
                    max = map.get(k).tot;
                    key = k;
                }
            }
            if (key.length > 0) {
                sort.set(key, map.get(key));
                map.delete(key);
            }
        }
        return sort;
    }
}
</script>