<template>
    <div>
        <div v-if="modalShow" class="socialFull">
            <div class="socialShowHideBtn" title="Свернуть" @click="modalShow=false">
                <span class="mdi mdi-chevron-double-up"></span>
            </div>
            <div style="overflow-y: scroll; height: 90%">
                <div style="display: table;">
                    <div
                        v-for="(soc, soc_indx) in socialLst"
                        :key="'soc_indx'+soc_indx"
                        style="display: table-row"
                    >
                        <div style="display: table-cell; width: 20px">
                            <c-socSrcIcon :source="soc.source"></c-socSrcIcon>
                        </div>
                        <div style="display: table-cell;">
                            <label
                                style="text-overflow:ellipsis; white-space: nowrap;  overflow: hidden"
                            >{{soc.name}}</label>
                        </div>
                        <div style="display: table-cell;">
                            <label v-if="soc.audienceSum!=null">{{'('+soc.audienceSum+')'}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-else>
            <div style="max-height: 380px; overflow: hidden;">
                <div style="display: table;">
                    <div
                        v-for="(soc, soc_indx) in shortSocLst"
                        :key="'soc_indx'+soc_indx"
                        style="display: table-row"
                    >
                        <div style="display: table-cell; width: 20px">
                            <c-socSrcIcon :source="soc.source"></c-socSrcIcon>
                        </div>
                        <div style="display: table-cell;">
                            <label
                                style="text-overflow:ellipsis; white-space: nowrap;  overflow: hidden"
                            >{{soc.name}}</label>
                        </div>
                        <div style="display: table-cell;">
                            <label v-if="soc.audienceSum!=null">{{'('+soc.audienceSum+')'}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="socialShowHideBtn"
                title="Развернуть"
                @click="modalShow=true"
                v-if="socialLst.length> shortSocLst.length"
            >
                <span class="mdi mdi-chevron-double-down"></span>
            </div>
            <!-- <div
                style="width: 100%; text-align: right; color: black; cursor: pointer"
                @click="modalShow=true"
            >Подробнее...</div>-->
        </template>
    </div>
</template>

<style lang="scss" scoped>
.socialFull {
    height: 85%;
    position: fixed;
    z-index: 2;
    background: white;
    width: 30%;
}
.socialShowHideBtn {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: blue;
    font-size: 25px;
    cursor: pointer;
}
</style>

<script lang="js">
import { Vue, Component, Prop } from 'vue-property-decorator'
import CSocialSourceIcon from '@/modules/socialnet/components/cSocialSourceIcon.vue'

/* export interface IAutor {
    name: string;
    source: string;
    audienceSum?: number | null;
} */

@Component({
  name: 'c-socList',
  components: {
    'c-socSrcIcon': CSocialSourceIcon
  }
})
export default class CSocialList extends Vue {
    @Prop({
      required: true,
      default: []
    })
    data;

    get socialLst () {
      return this.data
    }
    get shortSocLst () {
      const data = this.data.slice()
      data.length = 17
      return data
    }
    modalShow = false;
}
</script>
