<template>
  <div class="socmedia">
    <!-- Социальные сети -->
    <template v-if="type==1">
      <div class="filter-block">
        <div class="row">
          <!-- Периоды -->
          <div class="col period">
            <div class="row total" style="flex-wrap: nowrap; margin-bottom: 0.5rem; padding-right: 0.5rem; min-height: 2.1rem;">
              <span class="toneTot" style="font-style: italic; font-weight: bold; color: #7f7f7f; margin-right: 0.5rem;">Период:</span>
              <b-form-checkbox switch class="total" v-model="periods"></b-form-checkbox>
              <date-picker
                  v-if="selectFltr.period.selectPeriod"
                  v-model="selectedPeriod"
                  range
                  format="DD.MM.YYYY"
                  range-separator=" - "
                  :clearable="clearable"
                ></date-picker>
            </div>
            <div class="row">
              <div class="col-3" style="border: none;">
                <template v-for="(period, index) in periodLst">
                  <a href="#"
                    v-if="index>0 && index<periodLst.length-1"
                    :key="'per_indx'+ index"
                    :class='{active: selectFltr.period.value === period.value}'
                    style="margin-bottom: .25rem;"
                    @click="selectPeriod(period)">
                    {{period.name}}
                  </a>
                </template>
              </div>
              <a href="#"
                class="col-4 calendar"
                :class='{active: selectFltr.period.selectPeriod}'
                @click="selectPeriod(periodLst[4])">выбрать период</a>
            </div>

          </div>
           <!-- Тональность -->
          <div class="col tonality">
              <label class="total" style="margin-bottom: 1.6rem;">
                <span class="toneTot" style="font-style: italic; font-weight: bold; color: #7f7f7f;">{{tones.tot.name}}</span>
                <b-form-checkbox switch class="tone" v-model="tones.tot.switch"></b-form-checkbox>
              </label>
              <label>
                  <span class="tonePos">{{tones.pos.name}}</span>
                  <b-form-checkbox switch class="tonePos" v-model="tones.pos.switch"></b-form-checkbox>
              </label>
              <label>
                  <span class="toneNeg">{{tones.neg.name}}</span>
                  <b-form-checkbox switch class="toneNeg" v-model="tones.neg.switch"></b-form-checkbox>
              </label>
              <label>
                  <span class="toneNeut">{{tones.neut.name}}</span>
                  <b-form-checkbox switch class="toneNeut" v-model="tones.neut.switch"></b-form-checkbox>
              </label>
          </div>
          <!-- Соцсети -->
          <div class="col tonality">
            <label class="total" style="margin-bottom: 1.6rem;">
              <span class="toneTot">Соцсети</span>
              <b-form-checkbox switch class="tone" v-model="socialNetLst[0].switch" @change="selectNet(socialNetLst[0])"></b-form-checkbox>
            </label>

            <template v-for="(net, index) in socialNetLst">
              <a href="#" v-if="index>0" :key="'net.name_'+index" :class="['source', net.class, {'active': net.switch}]" :title="net.name" @click="selectNet(net)">
              </a>
            </template>
          </div>
          <!-- Выбор области и сферы -->
          <div class="col" style="padding-top: 3rem;">
            <multiselect
              placeholder="Выбрать регион"
              v-if="oblastLst!=null && oblastLst.length>0"
              v-model="selectFltr.obl"
              :options="oblastLst"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :searchable="true"
              :clearOnSelect="true"
              :hideSelected="true"

              label="text"
              track-by="text"
              :preselect-first="false"
              :disabled="loading"
            >
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} региона</span>
              </template>
            </multiselect>
            <div
                v-if="sphereLst!=null && sphereLst.length>0"
            >
              <multiselect
                v-model="selectFltr.sphere"
                :options="sphereLst"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :searchable="true"
                :clearOnSelect="true"
                :hideSelected="true"
                placeholder="Выбрать сферу"
                label="text"
                track-by="text"
                :preselect-first="false"
                :disabled="loading"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} сферы</span>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="row col">
          <a href="#" class="apply"
            :class='{disabled: loading}'
            @click="loadData(1)">Применить</a>
        </div>
      </div>
      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow">
      <div class="row search-block">
        <div class="col-sm-4" style="min-width: 406px; padding-left:0px; max-width: 408px">
          <!-- поиск по тексту -->
          <div class="row">
            <input type="text" v-model="socSearchTxt" @keypress.enter="loadData(1)" placeholder="по тексту" style="background-color: #fff; margin-right:3px; margin-bottom: 0.5rem"/>
            <input type="text" v-model="socSearchAutor" @keypress.enter="loadData(1)"  placeholder="по автору" style="background-color: #fff; margin-right:0px"/>
          </div>
          <div class="row" v-if="eventLstWithNull!=null && themeLoad==null" style="margin-top:5px">
            <multiselect
                        v-model="eventSelect"
                        track-by="id"
                        label="name"
                        placeholder="Выбрать событие"
                        :options="eventLstWithNull"
                        :searchable="true"
                        :allow-empty="false"
                    >
                        <template slot="singleLabel" slot-scope="{ option }">
                            <strong>{{ option.name }}</strong>
                        </template>
                    </multiselect>
          </div>
        </div>
        <div class="col"
          v-if="themeLoad!=null"
          style="color: #5e5e5e; font-size: 1.1rem; text-align: center"
        >{{themeLoad.name}}</div>
        <c-socMediaMetric :metricData="metricData"/>
        <template v-if="themeLoad!=null">
          <a
            v-if="themeLoad!=null && unrelevantData!=null && unrelevantData.length>0"
            title="Корзина"
            @click="modalBasket=true"
            class="ml-auto"
          >
            <div class="basket"></div>
          </a>
          <a href="#"
            title="Скачать"
            @click="downloadExcel(1)"
            :class="{'ml-auto': !(themeLoad!=null && unrelevantData!=null && unrelevantData.length>0)}"
            class="download"
          ></a>
        </template>
        <a href="#"
          :disabled="loading"
          @click="analyticMode = !analyticMode"
          :class="{'ml-auto': themeLoad === null, 'active': !analyticMode}"
          class="lenta"
        ></a>
      </div>
      <div class="datas-block">
        <c-socTab
          :data="socTabData"
          :infoData="socInfoData"
          :type="1"
          :theme="themeLoad"
          :eventLst="eventLst"
          :filterEvent = "eventSelect"
          v-show="!analyticMode"
          @saveEvent="saveEvent"
          @delUnrelevant="delUnrelevant"
        ></c-socTab>
        <template v-if="analyticMode">
          <c-socCharts
            :sectionData="{ section: section, datas: socSectionData.data }"
            :themeData="{ theme: themeLoad, period: curFltr.period, datas: socTabData }"
            :selectPeriod="curSelectedPeriod"
            :unrelevants="unrelevantData"
            :spheres="sphereLst"
            :socAnalData="socAnalData"
            :autorUserAnalData="autorUserAnalData"
            :autorGroupAnalData="autorGroupAnalData"
          ></c-socCharts>
        </template>
      </div>
      </b-overlay>
    </template>
    <!--------------------------Медия------------------------------------>
    <template v-if="type==2">
      <div class="filter-block">
        <div class="row">
          <div class="col period"> <!-- Периоды -->
            <div class="row total" style="flex-wrap: nowrap; margin-bottom: 0.5rem; padding-right: 0.5rem; min-height: 2.1rem;">
              <span class="toneTot" style="font-style: italic; font-weight: bold; color: #7f7f7f; margin-right: 0.5rem;">Период:</span>
              <b-form-checkbox switch class="total" v-model="periods"></b-form-checkbox>
              <date-picker
                v-if="selectFltr.period.selectPeriod"
                v-model="selectedPeriod"
                range
                format="DD.MM.YYYY"
                range-separator=" - "
                :clearable="clearable"
              ></date-picker>
            </div>
            <div class="row">
              <div class="col-3" style="border: none;">
                <template v-for="(period, index) in periodLst">
                  <a href="#"
                    v-if="index>0 && index<periodLst.length-1"
                    :key="'per_indx'+ index"
                    :class='{active: selectFltr.period === period}'
                    style="margin-bottom: .25rem;"
                    @click="selectPeriod(period)">
                    {{period.name}}
                  </a>
                </template>
              </div>
              <a href="#"
                class="col-4 calendar"
                :class='{active: selectFltr.period.selectPeriod}'
                @click="selectPeriod(periodLst[4])">выбрать период</a>
            </div>
          </div>
          <div class="col tonality"> <!-- Тональность -->
            <label class="total" style="margin-bottom: 1.6rem;">
              <span class="toneTot" style="font-style: italic; font-weight: bold; color: #7f7f7f;">{{tones.tot.name}}</span>
              <b-form-checkbox switch class="tone" v-model="tones.tot.switch"></b-form-checkbox>
            </label>
            <label>
                <span class="tonePos">{{tones.pos.name}}</span>
                <b-form-checkbox switch class="tonePos" v-model="tones.pos.switch"></b-form-checkbox>
            </label>
            <label>
                <span class="toneNeg">{{tones.neg.name}}</span>
                <b-form-checkbox switch class="toneNeg" v-model="tones.neg.switch"></b-form-checkbox>
            </label>
            <label>
                <span class="toneNeut">{{tones.neut.name}}</span>
                <b-form-checkbox switch class="toneNeut" v-model="tones.neut.switch"></b-form-checkbox>
            </label>
          </div>
          <div class="col" style="padding-top: 3rem;"> <!-- Выбор области и сферы -->
            <multiselect
              placeholder="Выбрать регион"
              v-if="oblastLst!=null && oblastLst.length>0"
              v-model="selectFltr.obl"
              :options="oblastLst"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :searchable="true"
              :clearOnSelect="true"
              :hideSelected="true"

              label="text"
              track-by="text"
              :preselect-first="false"
              :disabled="loading"
            >
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} региона</span>
              </template>
            </multiselect>
            <div v-if="sphereLst!=null && sphereLst.length>0">
              <multiselect
                v-model="selectFltr.sphere"
                :options="sphereLst"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :searchable="true"
                :clearOnSelect="true"
                :hideSelected="true"
                placeholder="Выбрать сферу"
                label="text"
                track-by="text"
                :preselect-first="false"
                :disabled="loading"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} сферы</span>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="row col">
          <a href="#" class="apply"
            :class='{disabled: loading}'
            @click="loadData(2)">Применить</a>
        </div>
      </div>
      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow">
        <div class="row search-block">
          <input type="text" v-model="medSearchTxt" @keypress.enter="loadData(2)"  placeholder="по тексту" style="background-color: #fff;"/>
          <input type="text" v-model="medSearchAutor" @keypress.enter="loadData(2)"  placeholder="СМИ" style="background-color: #fff;"/>
          <div class="col"
            v-if="themeLoad!=null"
            style="color: #5e5e5e; font-size: 1.1rem; text-align: center"
          >{{themeLoad.name}}</div>
          <c-socMediaMetric :metricData="metricData"/>
          <template v-if="themeLoad!=null">
            <a
              v-if="themeLoad!=null && unrelevantData!=null && unrelevantData.length>0"
              title="Корзина"
              @click="modalBasket=true"
              class="ml-auto"
            >
              <div class="basket"></div>
            </a>
            <a href="#"
              title="Скачать"
              @click="downloadExcel(2)"
              :class="{'ml-auto': !(themeLoad!=null && unrelevantData!=null && unrelevantData.length>0)}"
              class="download"
            ></a>
          </template>
          <a href="#"
            :disabled="loading"
            @click="analyticMode = !analyticMode"
            :class="{'ml-auto': themeLoad === null, 'active': !analyticMode}"
            class="lenta"
          ></a>
        </div>
        <div class="datas-block">
          <c-socTab
            :data="mediaTabData"
            :infoData="mediaInfoData"
            :type="2"
            :theme="themeLoad"
            :eventLst="eventLst"
            v-show="!analyticMode"
            @saveEvent="saveEvent"
            @delUnrelevant="delUnrelevant"
          ></c-socTab>
          <template v-if="analyticMode">
            <c-medCharts
              :sectionData="{ section: section, datas: medSectionData.data }"
              :themeData="{ theme: themeLoad, period: curFltr.period, datas: mediaTabData }"
              :selectPeriod="curSelectedPeriod"
              :unrelevants="unrelevantData"
            ></c-medCharts>
          </template>
        </div>
      </b-overlay>
    </template>
    <!--------------------------------------------------------------->
    <b-modal v-model="modalBasket" hide-footer size="xl">
        <template v-slot:modal-title>Нерелевантные</template>
        <div class="d-block text-center">
            <c-socTab
                :data="unrelevantData"
                :infoData="null"
                :type="type"
                :theme="null"
                :unrelevFl="true"
                @recover="unrelRecover"
            ></c-socTab>
        </div>
    </b-modal>
  </div>
</template>
<style lang="scss" scoped>
  div.mx-datepicker.mx-datepicker-range {
    width: 100% !important;
  }
</style>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import bootstrap from 'bootstrap-vue';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import CSocialButtonGrid from '@/modules/socialnet/components/cSocialButtonGrid.vue';
import СSocialTable from '@/modules/socialnet/components/cSocialTable.vue';
import CSocialList from '@/modules/socialnet/components/cSocialList.vue';
import cSocialCharts from '@/modules/socialnet/components/cSocialCharts.vue';
import cMediaCharts from '@/modules/socialnet/components/cMediaCharts.vue';
import DatePicker from 'vue2-datepicker';
import СSocMediaMetric from '@/modules/socialnet/components/cSocMediaMetric.vue';

Vue.use(bootstrap);

@Component({
    name: 'c-soc-media',
    components: {
        'c-socBtnGrd': CSocialButtonGrid,
        'c-socTab': СSocialTable,
        'c-socList': CSocialList,
        'multiselect': Multiselect,
        'c-socCharts': cSocialCharts,
        'c-medCharts': cMediaCharts,
        'date-picker': DatePicker,
        'c-socMediaMetric': СSocMediaMetric
    }
})
export default class CSocMedia extends Vue {
    @Prop({
        required: true,
        default: 1
    })
    type; // 1-соц сети, 2-медия

    @Prop({
        required: false,
        default: null
    })
    section; // темы выделенной секции

    @Prop({
        required: false,
        default: null
    })
    theme; // тема

    @Prop({
        required: false,
        default: null
    })
    period; // период

    @Prop({
        required: false,
        default: null
    })
    selPeriod; // период

    @Prop({
        required: false,
        default: null
    })
    tone; // период

    themeLoad = null; // текущая тема

    clearable = false;

    socData = null; // данные соц сетей
    mediaData = null; // данные масс-медия
    sphereData = null; // сферы с базы
    oblastData = null; // области с базы
    // ---------------------------------------------
    socialSearchTxt = ''; // поиск текста соц сети
    socialSearchAutor = ''; // поиск автора соц сети
    socSearchTxt = ''; // соц сети  текс в input
    socSearchAutor = ''; // соц сети автор в input
    // ---------------------------------------------
    mediaSearchTxt = ''; // поиск текста медия
    mediaSearchAutor = ''; // поиск автора медия
    medSearchTxt = ''; // текста медия в input
    medSearchAutor = ''; // автора медия в input
    // -----------------------------------------------
    selectFltr = { obl: [], sphere: [], period: { name: 'сутки', value: 1 }, socialNet: { name: 'Соц сети', value: null }, tone: { name: 'Все тональности', value: null } }; // параметры в селектах
    curFltr = { obl: [], sphere: [], period: { name: 'сутки', value: 1 }, socialNet: { name: 'Соц сети', value: null }, tone: { name: 'Все тональности', value: null } }; // параметры загруженных данных
    // socialSelect = { obl: [], sphere: [], period: { name: 'сутки', value: 1 }, socialNet: { name: 'Соц сети', value: null }, tone: { name: 'Все тональности', value: null } } // соц параметры загруженных данных
    // mediaSelect = { obl: [], sphere: [], period: { name: 'сутки', value: 1 }, socialNet: { name: 'Соц сети', value: null }, tone: { name: 'Все тональности', value: null } } // медия параметры загруженных данных
    // socSelect = { obl: [], sphere: [], period: { name: 'сутки', value: 1 }, socialNet: { name: 'Соц сети', value: null }, tone: { name: 'Все тональности', value: null } } // соц параметры в селектах
    // medSelect = { obl: [], sphere: [], period: { name: 'сутки', value: 1 }, socialNet: { name: 'Соц сети', value: null }, tone: { name: 'Все тональности', value: null } } // медия параметры в селектах
    // ----------------------------------------------
    curSelectedPeriod = null; // массив выбираемого периода
    selectedPeriod = null; // период в datePicker
    // socSelectedPeriod = null // массив выбираемого периода в соц сетях
    // mediaSelectedPeriod = null // массив выбираемого периода в медия
    // socPeriod = null // соц сети, период в datePicker
    // medPeriod = null // медия, период в datePicker
    // -----------------------------------------------
    loading = false; // показывать колесо загрузки
    periodLst = [{ name: 'Весь', value: null },
        { name: 'сутки', value: 1 },
        { name: 'неделя', value: 7 },
        { name: 'месяц', value: 30 },
        { name: 'выбрать период', value: null, selectPeriod: true }]; // список периодов
    periods = false;

    socialNetLst = // список соц сетей
    [{ name: 'Соц сети', value: null, switch: true },
        { name: 'Instagram', value: 1, switch: false, class: 'insta' },
        { name: 'Facebook', value: 2, switch: false, class: 'facebook' },
        { name: 'VKontakte', value: 3, switch: false, class: 'vk' },
        { name: 'YouTube', value: 4, switch: false, class: 'youtube' },
        { name: 'Telegram', value: 5, switch: false, class: 'telegram' }];

    toneLst = [{ name: 'Все тональности', value: null }, { name: 'Негативная', value: -2 }, { name: 'Нейтральная', value: 1 }, { name: 'Позитивная', value: 2 }]; // список тональности
    tones = { tot: { name: 'Тональность', switch: true, value: null },
        pos: { name: 'Позитивная', switch: false, value: 2 },
        neg: { name: 'Негативная', switch: false, value: -2 },
        neut: { name: 'Нейтральная', switch: false, value: 1 }
    };
    socInfoData = { all: 0, pos: 0, neg: 0, neut: 0 };
    metricData = { all: 0, autors: null, involvemed: 0, loyalty: 0 };
    mediaInfoData = { all: 0, pos: 0, neg: 0, neut: 0 };
    analyticMode = false; // режим аналитика
    analyticModeTab = 0; // 0-тональность, 1-соц сети, 2-авторы

    autorUserAnalData = []; // данные аналитика по автору (пользователь)
    autorGroupAnalData = []; // данные аналитика по автору (группы)

    socAnalData = []; // данные аналитика по соц сетям
    unrelevantData = []; // нерелевантные данные
    modalBasket = false; // показать модальное окно по корзине нерелевантных

    socSectionData = []; // данные соц сетей по темам раздела
    medSectionData = []; // данные медия по темам раздела

    // --------------------------
    ajxSocParams = null; // параметры на загрузку соц сети
    ajxMedParams = null; // параметры на загрузку медия
    // ----------------------------
    eventLst = null; // список событий
    eventLstWithNull = null; // список событий eventLst + пустой элемент
    eventSelect = null;

    created() {
        this.$watch('periods', (val) => {
            if (val) {
                this.selectFltr.period = this.periodLst[0];
            } else {
                if (this.selectFltr.period.value === this.periodLst[0].value) {
                    this.selectFltr.period = this.periodLst[1];
                }
            }
        });
        this.$watch('selectFltr.period.selectPeriod', (val) => {
            if (val) {
                this.periods = false;
            }
        });
        this.$watch('tones.tot.switch', (val) => {
            console.log('tones.tot.switch');
            if (val) {
                this.tones.pos.switch = false;
                this.tones.neg.switch = false;
                this.tones.neut.switch = false;
                this.selectFltr.tone = this.tones.tot;
            } else {
                if (!this.tones.pos.switch && !this.tones.neg.switch && !this.tones.neut.switch) {
                    this.tones.pos.switch = true;
                }
            }
        });
        this.$watch('tones.pos.switch', (val) => {
            console.log('tones.pos.switch');
            if (val) {
                this.tones.tot.switch = false;
                this.tones.neg.switch = false;
                this.tones.neut.switch = false;
                this.selectFltr.tone = this.tones.pos;
            }
        });
        this.$watch('tones.neg.switch', (val) => {
            if (val) {
                this.tones.pos.switch = false;
                this.tones.tot.switch = false;
                this.tones.neut.switch = false;
                this.selectFltr.tone = this.tones.neg;
            }
        });
        this.$watch('tones.neut.switch', (val) => {
            if (val) {
                this.tones.pos.switch = false;
                this.tones.neg.switch = false;
                this.tones.tot.switch = false;
                this.selectFltr.tone = this.tones.neut;
            }
        });
        this.$watch('selectFltr.socialNet.value', (val) => {
            for (const net of this.socialNetLst) {
                net.switch = false;
            }
            switch (val) {
                case null:
                    this.socialNetLst[0].switch = true;
                    break;
                case 1:
                    this.socialNetLst[1].switch = true;
                    break;
                case 2:
                    this.socialNetLst[2].switch = true;
                    break;
                case 3:
                    this.socialNetLst[3].switch = true;
                    break;
                case 4:
                    this.socialNetLst[4].switch = true;
                    break;
                case 5:
                    this.socialNetLst[5].switch = true;
                    break;
            }
        });
    }

    mounted() {
        this.selectFltr.period = (this.period === null ? this.periodLst[1] : this.period);
        if (this.tone !== null) {
            if (this.tone.value === this.tones.tot.value) {
                this.tones.tot.switch = true;
            }
            if (this.tone.value === this.tones.pos.value) {
                this.tones.pos.switch = true;
            }
            if (this.tone.value === this.tones.neg.value) {
                this.tones.neg.switch = true;
            }
            if (this.tone.value === this.tones.neut.value) {
                this.tones.neut.switch = true;
            }
            this.selectFltr.tone = this.tone;
        }
        this.selectedPeriod = this.selPeriod;
        this.loadEvents();
        this.loadOblast();
        this.loadSphere();
        this.loadData(this.type);
    }

    selectPeriod(period) {
        this.selectFltr.period = period;
        this.periods = false;
    }

    selectNet(net) {
        net.switch = !net.switch;
        if (net.switch) {
            this.selectFltr.socialNet = net;
        }
    }

    get autorList() {
        const data = [];
        for (let i = 0; i < 100; i++) {
            data.push(
                { name: i + ' пользователь', source: 'instagram', audienceSum: Math.round(1000 * Math.random() + Math.random() * 10) },
                { name: i + ' пользователь', source: 'facebook' }
            );
        }
        return data;
    }

    async loadData(type) { // type==1 (соц сети), type==2 (масс-медия)
        const that = this;
        that.socData = [];
        that.unrelevantData = [];
        that.mediaData = [];
        const oblasts = [];
        const spheres = [];
        let searchTxt = null;
        let searchAutor = null;
        let selectedPeriod = null;
        let keywords = null;
        let attrSearch = false;
        that.eventSelect = { id: -1, name: 'ВСЕ' };

        that.themeLoad = that.theme;

        if (that.themeLoad != null) {
            keywords = that.themeLoad.keywords;
            if (that.themeLoad.attrSearch != null && that.themeLoad.attrSearch !== undefined) {
                attrSearch = that.themeLoad.attrSearch;
            }
        }
        this.curSelectedPeriod = this.selectedPeriod;
        selectedPeriod = this.getSelectedPeriod(this.curSelectedPeriod, this.curFltr);
        if (type === 1) {
            this.socialSearchAutor = this.socSearchAutor;
            this.socialSearchTxt = this.socSearchTxt;
            this.curFltr = Object.assign({}, this.selectFltr);
            for (const sp of this.curFltr.sphere) {
                spheres.push(sp.value);
            }
            for (const obl of this.curFltr.obl) {
                oblasts.push(obl.value);
            }
            this.curSelectedPeriod = this.selectedPeriod;
            selectedPeriod = this.getSelectedPeriod(this.curSelectedPeriod, this.curFltr);
            if (this.socialSearchTxt != null && this.socialSearchTxt.trim() !== '') {
                searchTxt = this.socialSearchTxt.trim();
            }
            if (this.socialSearchAutor != null && this.socialSearchAutor.trim() !== '') {
                searchAutor = this.socialSearchAutor.trim();
            }
        } else {
            this.mediaSearchTxt = this.medSearchTxt;
            this.mediaSearchAutor = this.medSearchAutor;
            if (this.mediaSearchTxt != null && this.mediaSearchTxt.trim() !== '') {
                searchTxt = this.mediaSearchTxt.trim();
            }
            if (this.mediaSearchAutor != null && this.mediaSearchAutor.trim() !== '') {
                searchAutor = this.mediaSearchAutor.trim();
            }
            this.curFltr = Object.assign({}, this.selectFltr);
            for (const obl of this.curFltr.obl) {
                oblasts.push(obl.value);
            }
            for (const sp of this.curFltr.sphere) {
                spheres.push(sp.value);
            }
        }
        this.loading = true;

        if (type === 1) {
            this.ajxSocParams = { params: { 'type': type, 'oblasts': oblasts, 'spheres': spheres, 'period': that.curFltr.period.value, 'search_text': searchTxt, 'search_autor': searchAutor, 'socialNet': that.curFltr.socialNet.value, 'tone': that.curFltr.tone.value, 'selectedPeriod': selectedPeriod, 'keywords': keywords, 'attrSearch': attrSearch } };
            await axios.post('api-py/mm-post/report-form', this.ajxSocParams).then(response => (that.socData = this.getUnrelevant(response.data, that.themeLoad)));
            this.calcAnalyticData();
            if (that.section != null) {
                await axios
                    .post('api-py/mm-post/report-section', { params: { 'type': type, 'oblasts': oblasts, 'spheres': spheres, 'period': that.curFltr.period.value, 'search_text': searchTxt, 'search_autor': searchAutor, 'socialNet': that.curFltr.socialNet.value, 'tone': that.curFltr.tone.value, 'selectedPeriod': selectedPeriod, 'section': that.section } })
                    .then(response => (that.socSectionData = response.data));
            }
        } else {
            this.ajxMedParams = { params: { 'type': type, 'oblasts': oblasts, 'spheres': spheres, 'period': that.curFltr.period.value, 'tone': that.curFltr.tone.value, 'selectedPeriod': selectedPeriod, 'search_text': searchTxt, 'search_autor': searchAutor, 'keywords': keywords, 'attrSearch': attrSearch } };
            await axios
                .post('api-py/mm-post/report-form', this.ajxMedParams)
                .then(response => (that.mediaData = this.getUnrelevant(response.data, that.themeLoad)));
            if (that.section != null) {
                await axios
                    .post('api-py/mm-post/report-section', { params: { 'type': type, 'oblasts': oblasts, 'spheres': spheres, 'period': that.curFltr.period.value, 'search_text': searchTxt, 'search_autor': searchAutor, 'tone': that.curFltr.tone.value, 'selectedPeriod': selectedPeriod, 'section': that.section } })
                    .then(response => (that.medSectionData = response.data));
            }
        }
        this.loading = false;
    }

    getUnrelevant(data, theme) { // вытащить нерелевантные данные
        if (theme == null) { return data; }
        if (data === null || data.data === undefined) { return []; }
        const resData = [];
        const resUnrel = [];
        for (const el of data.data) {
            let fl = false;
            if (el.unrelevant !== undefined && el.unrelevant != null) {
                for (const unEl of el.unrelevant) {
                    if (unEl === theme.id) {
                        fl = true;
                        break;
                    }
                }
            }
            if (fl) {
                resUnrel.push(el);
            } else {
                resData.push(el);
            }
        }
        this.unrelevantData = resUnrel;
        return { 'data': resData, 'status': data.status };
    }

    getSelectedPeriod(selectedPeriod, select) {
        let result = null;
        if (select.period.selectPeriod) {
            if (selectedPeriod == null || selectedPeriod.length < 2 || selectedPeriod[0] == null || selectedPeriod[1] == null) {
                alert('Не выбран период!');
                return null;
            }
            result = [];
            result.push(selectedPeriod[0].getTime() / 1000);
            const eDate = selectedPeriod[1];
            eDate.setHours(23, 59, 59);
            result.push(eDate.getTime() / 1000);
        }
        return result;
    }

    // ---------------------------расчет аналитика---------------------
    calcAnalyticData() {
        const cloneSoc = this.cloneArr(this.socData.data);
        // -------------
        const sortAutor = cloneSoc.sort(function (d1, d2) { return (d1.autor.toLowerCase() > d2.autor.toLowerCase()) ? 1 : -1; });
        const autorUser = [];
        const autorGroup = [];
        this.socAnalData = [];
        const socAnalData = new Map();
        for (const el of sortAutor) {
            if (!socAnalData.has(el.socialNet)) {
                socAnalData.set(el.socialNet, { pos: 0, neg: 0, sum: 0, neut: 0 });
            }
            // ----------------
            let autorEl = null;
            if (el.isGroup) {
                if (autorGroup.length === 0 || autorGroup[autorGroup.length - 1].autor !== el.autor) {
                    let folowers = -1;
                    if (el.folowers !== undefined && el.folowers !== null) { folowers = el.folowers; }
                    autorGroup.push({ socialNet: el.socialNet, autor: el.autor, pos: 0, neg: 0, sum: 0, neut: 0, folowers: folowers, involvemed: 0 });
                }
                autorEl = autorGroup[autorGroup.length - 1];
            } else {
                if (autorUser.length === 0 || autorUser[autorUser.length - 1].autor !== el.autor) {
                    let folowers = -1;
                    if (el.folowers !== undefined && el.folowers !== null) { folowers = el.folowers; }
                    autorUser.push({ socialNet: el.socialNet, autor: el.autor, pos: 0, neg: 0, sum: 0, neut: 0, folowers: folowers, involvemed: 0 });
                }
                autorEl = autorUser[autorUser.length - 1];
            }
            // -------------------------
            const obj = socAnalData.get(el.socialNet);
            if (el.tone === -2) { // негативная
                autorEl.neg++;
                obj.neg++;
            } else if (el.tone === 2) { // позитивная
                autorEl.pos++;
                obj.pos++;
            } else if (el.tone === 1) {
                autorEl.neut++;
                obj.neut++;
            }
            autorEl.sum++;
            // ------------involvemed
            autorEl.involvemed = autorEl.involvemed + this.calcInvolmed(el);
            // ------------
            obj.sum++;
            socAnalData.set(el.socialNet, obj);
        }
        autorUser.sort(function (d1, d2) { return (d2.sum - d1.sum); });
        autorGroup.sort(function (d1, d2) { return (d2.sum - d1.sum); });
        this.autorUserAnalData = autorUser;
        this.autorGroupAnalData = autorGroup;
        let allSum = 0;
        for (const pair of socAnalData.entries()) {
            this.socAnalData.push(pair[1]);
            allSum = allSum + pair[1].sum;
            this.socAnalData[this.socAnalData.length - 1].socialNet = pair[0];
        }
        for (const el of this.socAnalData) {
            el.percent = Math.round(el.sum / allSum * 10000) / 100;
        }
    }
    // -------------------------------------------------------------

    calcInvolmed(el) { // расчёт вовлечённости
        let involvemed = 0;
        if (el.commentSum !== undefined && el.commentSum !== null) { involvemed = involvemed + el.commentSum; }
        if (el.likeSum !== undefined && el.likeSum !== null) { involvemed = involvemed + el.likeSum; }
        if (el.shareSum !== undefined && el.shareSum !== null) { involvemed = involvemed + el.shareSum; }
        return involvemed;
    }

    loadSphere() {
        const that = this;
        axios
            .get('api-py/mm-data/spheres')
            .then(response => (that.sphereData = response.data));
    }

    async loadEvents() {
        const that = this;
        const response = await axios.get('api-py/mm-data/events-list');
        that.eventLst = response.data.events_list;
        that.eventLstWithNull = response.data.events_list;
        that.eventLstWithNull.unshift({ id: -1, name: 'ВСЕ' });
        that.eventSelect = { id: -1, name: 'ВСЕ' };
    }

    get sphereLst() {
        if (this.sphereData === undefined || this.sphereData === null) { return []; }
        if (this.sphereData.status === 'success') {
            return this.sphereData.spheres;
        }
        return [];
    }

    get socTabData() {
        this.socInfoData = { all: 0, pos: 0, neg: 0, neut: 0 };
        if (this.socData === undefined || this.socData === null) { return []; }
        if (this.socData.status === 'success') {
            this.getMetricData(this.socData.data);
            let pos = 0;
            let neg = 0;
            let neut = 0;
            for (const el of this.socData.data) {
                if (el.tone === -2) { neg++; } else if (el.tone === 1) { neut++; } else if (el.tone === 2) { pos++; }
            }
            this.socInfoData = { all: this.socData.data.length, pos: pos, neg: neg, neut: neut };
            return this.socData.data;
        }
        return [];
    }

    get mediaTabData() {
        if (this.mediaData === undefined || this.mediaData === null) { return []; }
        if (this.mediaData.status === 'success') {
            this.getMetricData(this.mediaData.data);
            let pos = 0;
            let neg = 0;
            let neut = 0;
            for (const el of this.mediaData.data) {
                if (el.tone === -2) { neg++; } else if (el.tone === 1) { neut++; } else if (el.tone === 2) { pos++; }
            }
            this.mediaInfoData = { all: this.mediaData.data.length, pos: pos, neg: neg, neut: neut };
            return this.mediaData.data;
        }
        return [];
    }

    getMetricData(data) {
        const that = this;
        that.metricData = { all: 0, autors: null, involvemed: 0, loyalty: 0 };
        if (data === undefined) { return; }
        const autors = [];
        let involvemed = 0;
        let pos = 0;
        let neg = 0;
        that.metricData.all = data.length;
        for (const el of data) {
            if (el.tone === -2) { neg++; } else if (el.tone === 2) { pos++; }
            if (that.type === 1) {
                // -------авторы
                let fl = false;
                for (const aut of autors) {
                    if (aut === el.autor) {
                        fl = true;
                        break;
                    }
                }
                if (!fl) { autors.push(el.autor); }
            }
            // --------
            involvemed = involvemed + that.calcInvolmed(el);
        }
        if (neg !== 0) {
            that.metricData.loyalty = Math.round(pos / neg * 100) / 100;
        } else {
            that.metricData.loyalty = 1;
        }
        if (that.type === 1) { that.metricData.autors = autors.length; }
        that.metricData.involvemed = involvemed;
    }

    loadOblast() {
        const that = this;
        axios
            .get('api-py/mm-data/oblasts')
            .then(response => (that.oblastData = response.data));
    }

    get oblastLst() {
        if (this.oblastData === undefined || this.oblastData === null) { return []; }
        if (this.oblastData.status === 'success') {
            return this.oblastData.oblasts;
        }
        return [];
    }

    cloneArr(arr) {
        const newArr = [];
        for (const el of arr) {
            newArr.push(Object.assign({}, el));
        }
        return newArr;
    }

    // загрузка с excell
    async downloadExcel(type) { // type==1 (соц сети), type==2 (масс-медия)
        const that = this;
        let sect = '';
        let per = '';
        let net = '';
        let perId = 0;
        if (type === 1) {
            axios(
                {
                    url: 'api-py/mm-post/topic_download/',
                    method: 'POST',
                    data: that.ajxSocParams,
                    responseType: 'blob'
                }
            )
                .then(response => {
                    console.log('Type3', response.data);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    sect = that.theme.name;
                    perId = that.curFltr.period.value;
                    net = that.curFltr.socialNet.name;
                    console.log(perId);
                    if ((perId != null)) {
                        per = that.curFltr.period.name;
                    } else {
                        per = that.selectedPeriod[0].toLocaleDateString('ru') + '_' + that.selectedPeriod[1].toLocaleDateString('ru');
                    }
                    link.setAttribute('download', sect + '_' + net + '_' + per + '.xlsx'); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
        } else {
            axios(
                {
                    url: 'api-py/mm-post/topic_download/',
                    method: 'POST',
                    data: that.ajxMedParams,
                    responseType: 'blob'
                }
            )
                .then(response => {
                    console.log('Type3', response.data);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    sect = that.theme.name;
                    perId = that.curFltr.period.value;
                    console.log('perId', perId);
                    if ((perId != null)) {
                        per = that.curFltr.period.name;
                    } else {
                        per = that.selectedPeriod[0].toLocaleDateString('en-GB') + '_' + that.selectedPeriod[1].toLocaleDateString('en-GB');
                    }
                    link.setAttribute('download', sect + '_масс_медиа_' + per + '.xlsx'); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
        }
    }

    async unrelRecover(items) {
        const that = this;
        if (items == null) { return; }
        const topicid = that.themeLoad.id;
        const newItems = that.cloneArr(items);
        let response = null;
        that.loading = true;
        const params = [];
        for (const item of items) {
            params.push({
                'type': that.type,
                'post_id': item.id,
                'topic_id': topicid,
                'smi': item.smi,
                'socialNet': item.socialNet
            });
        }
        response = await axios.post('delete-not-relevant', { params: params });
        that.loading = false;
        if (response.data === 'success') {
            for (let it = 0; it < newItems.length; it++) {
                const item = newItems[it];
                for (let i = 0; i < that.unrelevantData.length; i++) {
                    const el = that.unrelevantData[i];
                    if ((that.type === 1 && el.id === item.id && item.socialNet === el.socialNet) || (that.type === 2 && el.id === item.id)) {
                        that.unrelevantData.splice(i, 1);
                        for (let j = 0; j < newItems[it].unrelevant.length; j++) {
                            if (newItems[it].unrelevant[j] === topicid) {
                                newItems[it].unrelevant.splice(j, 1);
                                break;
                            }
                        }
                        break;
                    }
                }

                if (that.type === 1) {
                    that.socData.data.unshift(newItems[it]);
                } else {
                    that.mediaData.data.unshift(newItems[it]);
                }
                if (that.unrelevantData.length === 0) {
                    that.modalBasket = false;
                }
            }
        } else {
            alert('Ошибка восстановления!');
            console.log('Ошибка', response);
        }
    }

    async saveEvent(prop) { // сохранение событий
        const that = this;
        if (prop.items.length === 0) {}
        const params = [];
        let event = { 'name': null, 'id': null };
        if (prop.event != null) { event = prop.event; }

        for (const item of prop.items) {
            let smi = null;
            let socialNet = null;
            if (that.type === 2) { smi = item.smi; } else { socialNet = item.socialNet; }
            params.push({ 'post_id': item.id, 'type': that.type, 'event_id': event.id, 'smi': smi, 'socialNet': socialNet });
        }
        const result = await axios.post('set-new-event', { params: params });
        if (result.status !== 200) {
            alert('Ошибка сохранения!');
            console.log('Ошибка set-new-event', result);
            return;
        }
        let arrData = null;
        if (that.type === 1) { arrData = that.socData; } else { arrData = that.mediaData; }

        for (const item of prop.items) {
            for (let i = 0; i < arrData.data.length; i++) {
                const el = arrData.data[i];
                if ((that.type === 1 && el.id === item.id && item.socialNet === el.socialNet) || (that.type === 2 && el.id === item.id && item.smi === el.smi)) {
                    el.eventId = event.id;
                    el.eventName = event.name;
                    break;
                }
            }
        }
    }

    async delUnrelevant(items) { // удаление в нерелевантные
        const that = this;
        const topicid = this.themeLoad.id;
        let response = null;
        const params = [];

        for (const item of items) {
            params.push({
                'type': that.type,
                'post_id': item.id,
                'topic_id': topicid,
                'smi': item.smi,
                'socialNet': item.socialNet
            });
        }
        response = await axios.post('api-py/mm-post/not-relevant', { params: params }
        );

        that.loading = false;
        if (response.status !== 200) {
            alert('Ошибка!');
            console.log('response', response);
            return;
        }
        let arrData = null;
        if (that.type === 1) { arrData = that.socData; } else { arrData = that.mediaData; }
        for (const item of items) {
            for (let i = 0; i < arrData.data.length; i++) {
                const el = arrData.data[i];
                if ((that.type === 1 && el.id === item.id && item.socialNet === el.socialNet) || (that.type === 2 && el.id === item.id)) {
                    arrData.data.splice(i, 1);
                    if (el.unrelevant == null || el.unrelevant === undefined) { el.unrelevant = []; }
                    el.unrelevant.push(topicid);
                    that.unrelevantData.unshift(el);
                    break;
                }
            }
        }
    }
}
</script>