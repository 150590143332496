<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Chart } from 'highcharts-vue'

@Component({
  name: 'd-langPie',
  components: {
    highcharts: Chart
  }
})
export default class DMediaLangPie extends Vue {
  @Prop({
    required: false,
    default: {}
  })
  theme

  @Prop({
    required: false,
    default: null
  })
  period

  @Prop({
    required: true,
    default: []
  })
  datas

  @Prop({
    required: false,
    default: []
  })
  unrelevants

  chartOptions = {}

  created () {
    this.$watch('datas', () => {
      this.updateOptions()
    })
  }

  mounted () {
    this.updateOptions()
  }

  updateOptions () {
    const colors = ['#f8cbad', '#bfbfbf', '#3b7cb7', '#e37979', '#bdd7ee']
    const series = []
    let kz = 0
    let ru = 0
    let en = 0

    this.datas.forEach((item) => {
      if (this.unrelevants.indexOf(item.id) === -1) {
        switch (item.lang) {
          case 'kz':
            kz++
            break
          case 'ru':
            ru++
            break
          default:
            en++
            break
        }
      }
    })

    series.push({name: 'Казахский', y: kz})
    series.push({name: 'Русский', y: ru})
    series.push({name: 'Другие', y: en})

    this.chartOptions = {
      colors,
      chart: {
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{series.name} <b>{point.y}</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            alignTo: 'connectors',
            connectorShape: 'crookedLine',
            crookDistance: '50%',
            enabled: true,
            format: '<span style="color:{point.color}">{point.percentage:.1f}%</span>',
            filter: {
              property: 'percentage',
              operator: '>',
              value: 0
            },
            style: {
              fontSize: '1.2rem',
              fontWeight: '300',
              textOutline: '0px'
            }
          },
          showInLegend: true
        }
      },
      legend: {
        itemStyle: {
          color: '#5e5e5e',
          fontSize: '.9rem',
          fontWeight: 'normal'
        }
      },
      series: [{
        type: 'pie',
        name: '',
        data: series
      }],
      credits: {
        enabled: false
      }
    }
  }
}
</script>
