<template>
  <div class="chart-block">
    <b-tabs>
      <b-tab title="Тональность" active>
        <div class="diagram">
          <a v-if="$route.path==='/topic'" title="Сравнить темы"
            class="compare" :class="{'active': compare}"
            @click="compare = !compare"></a>
          <template v-if="!compare">
          <d-toneLine v-if="(themeData.datas !== null && themeData.datas.length !== 0)"
            :selectPeriod="selectPeriod"
            :theme="themeData.theme"
            :period="themeData.period"
            :datas="themeData.datas"
            :unrelevants="unrelArray"
            >
          </d-toneLine>
          <d-toneColumn v-if="(themeData.datas !== null && themeData.datas.length !== 0)"
            :theme="themeData.theme"
            :period="themeData.period"
            :datas="themeData.datas"
            :unrelevants="unrelArray"
            >
          </d-toneColumn>
          </template>
          <template v-else>
          <d-tonesBar v-if="(sectionData.section !== null) && (sectionData.datas !== undefined) && (sectionData.datas !== null) && (sectionData.datas.length !== 0)"
            :section="sectionData.section"
            :datas="sectionData.datas"
            :unrelevants="unrelArray"
            >
          </d-tonesBar>
          </template>
        </div>
      </b-tab>
      <b-tab title="Топ СМИ">
        <div class="diagram">
        <template>
          <d-smiTonesBar v-if="themeData.datas !== null && themeData.datas.length !== 0"
            :theme="themeData.theme"
            :period="themeData.period"
            :datas="themeData.datas"
            :unrelevants="unrelArray"
            >
          </d-smiTonesBar>
        </template>
        </div>
      </b-tab>
      <b-tab title="Язык">
        <div class="diagram">
          <a v-if="$route.path==='/topic'" title="Сравнить темы"
            class="compare" :class="{'active': compare}"
            @click="compare = !compare"></a>
          <template v-if="!compare">
          <d-langLine v-if="themeData.datas !== null && themeData.datas.length !== 0"
            :selectPeriod="selectPeriod"
            :theme="themeData.theme"
            :period="themeData.period"
            :datas="themeData.datas"
            :unrelevants="unrelArray"
            >
          </d-langLine>
          <d-langPie v-if="themeData.datas !== null && themeData.datas.length !== 0"
            :theme="themeData.theme"
            :period="themeData.period"
            :datas="themeData.datas"
            :unrelevants="unrelArray"
            >
          </d-langPie>
          </template>
          <template v-else>
          <d-langsBar v-if="(sectionData.section !== null) && (sectionData.datas !== undefined) && (sectionData.datas !== null) && (sectionData.datas.length !== 0)"
            :section="sectionData.section"
            :datas="sectionData.datas"
            :unrelevants="unrelArray"
            >
          </d-langsBar>
          </template>
          </div>
      </b-tab>
      <b-tab title="События">
        <div class="diagram">
          <d-eventTonesBar v-if="themeData.datas !== null || themeData.datas.length !== 0"
            :theme="themeData.theme"
            :period="themeData.period"
            :datas="themeData.datas"
            :unrelevants="unrelArray"
            >
          </d-eventTonesBar>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script lang="js">
import { Vue, Prop, Component } from 'vue-property-decorator'

import dMediaLangLine from '@/modules/socialnet/components/dMediaLangLine.vue'
import dMediaLangPie from '@/modules/socialnet/components/dMediaLangPie.vue'
import dMediaToneLine from '@/modules/socialnet/components/dMediaToneLine.vue'
import dMediaToneColumn from '@/modules/socialnet/components/dMediaToneColumn.vue'
import dMediaSmiTonesBar from '@/modules/socialnet/components/dMediaSmiTonesBar.vue'
import dSocialTonesBar from '@/modules/socialnet/components/dSocialTonesBar.vue'
import dSocialLangsBar from '@/modules/socialnet/components/dSocialLangsBar.vue'
import dEventTonesBar from '@/modules/socialnet/components/dEventTonesBar.vue'

import bootstrap from 'bootstrap-vue'

Vue.use(bootstrap)

@Component({
  name: 'c-medCharts',
  components: {
    'd-langLine': dMediaLangLine,
    'd-tonesBar': dSocialTonesBar,
    'd-langPie': dMediaLangPie,
    'd-toneLine': dMediaToneLine,
    'd-toneColumn': dMediaToneColumn,
    'd-smiTonesBar': dMediaSmiTonesBar,
    'd-langsBar': dSocialLangsBar,
    'd-eventTonesBar': dEventTonesBar
  }
})
export default class CMediaCharts extends Vue {
  @Prop({
    required: false,
    default: {section: null, datas: null}
  })
  sectionData

  @Prop({
    required: false,
    default: {theme: null, period: {}, datas: []}
  })
  themeData

  @Prop({
    required: false,
    default: null
  })
  selectPeriod

  @Prop({
    required: false,
    default: []
  })
  unrelevants // нерелевантные данные

  unrelArray = []
  compare = false

  mounted () {
    if (this.themeData.datas !== null || this.themeData.datas.length !== 0) {
      this.themeData.datas.sort(this.sortByField('bDate'))
    }

    for (const e of this.unrelevants) {
      this.unrelArray.push(e.id)
    }
  }

  sortByField (field) {
    return (a, b) => (a[field] > b[field] ? 1 : -1)
  }
}

</script>
