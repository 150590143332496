<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';

@Component({
    name: 'd-tonesBar',
    components: {
        highcharts: Chart
    }
})
export default class DSocialTonesBar extends Vue {
    @Prop({
        required: false,
        default: null
    })
    section;

    @Prop({
        required: false,
        default: null
    })
    datas;

    @Prop({
        required: false,
        default: []
    })
    unrelevants;

    chartOptions = {};

    created() {
        this.$watch('datas', () => {
            this.updateOptions();
        });
    }

    mounted() {
        this.updateOptions();
    }

    updateOptions() {
        const colors = ['#f8cbad', '#bfbfbf', '#3b7cb7', '#e37979', '#bdd7ee']; /* '#1f4e79', */
        const series = [];
        const categories = [];
        const total = [];
        const negative = [];
        const neutral = [];
        const positive = [];

        this.datas.forEach((item) => {
            if (item.datas.data.length > 0) {
                categories.push(item.theme.name);

                let tot = 0;
                let neg = 0;
                let pos = 0;
                let neut = 0;
                item.datas.data.forEach((post) => {
                    if (this.unrelevants.indexOf(post.id) === -1) {
                        switch (post.tone) {
                            case -2:
                                neg++;
                                break;
                            case 1:
                                neut++;
                                break;
                            case 2:
                                pos++;
                                break;
                            default:
                                break;
                        }
                        tot++;
                    }
                });
                total.push(tot);
                negative.push(neg);
                positive.push(pos);
                neutral.push(neut);
            }
        });
        // series.push({name: 'Всего', data: total})
        series.push({ name: 'Негативная', data: negative });
        series.push({ name: 'Нейтральная', data: neutral });
        series.push({ name: 'Позитивная', data: positive });

        this.chartOptions = {
            colors,
            chart: {
                type: 'bar'
            },
            title: {
                text: (this.section.section !== null ? this.section.section.name : ''),
                style: {
                    color: '#5e5e5e',
                    fontSize: '1rem'
                }
            },
            xAxis: {
                categories: categories,
                labels: {
                    style: {
                        fontSize: '1rem',
                        color: '#5e5e5e'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            legend: {
                itemStyle: {
                    color: '#5e5e5e',
                    fontSize: '.9rem',
                    fontWeight: 'normal'
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        backgroundColor: '#fff',
                        borderColor: '#f2f2f2',
                        borderWidth: '1px',
                        formatter() {
                            return (this.y > 0 ? '<span style="color: ' + this.point.color + '">' + this.y + '</span>' : '');
                        },
                        style: {
                            fontSize: '.8rem',
                            fontWeight: 'normal',
                            color: '#fff',
                            textOutline: '0'
                        }
                    }
                },
                series: {
                    stacking: 'normal',
                    pointWidth: 20
                }
            },
            series: series,
            credits: {
                enabled: false
            }
        };
    }
}
</script>