<template>
    <div>
        <template v-for="(pbT, pbT_indx) in publishType">
            <div class="publishBtn" :key="'pbT_indx_btn'+pbT_indx" :title="'тип '+pbT">
                <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Emblem_of_Nur-Sultan-1.svg/200px-Emblem_of_Nur-Sultan-1.svg.png"
                />
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.publishBtn img {
    width: 100%;
    height: 100%;
}
div.publishBtn {
    margin-left: 10px;
    margin-top: 10px;
    width: 100px;
    height: 100px;
    border: 2px inset black;
    display: inline-block;
}

div.publishBtn {
    margin-left: 10px;
    margin-top: 10px;
    width: 80px;
    height: 80px;
    display: inline-block;
    font-weight: 700;
    color: white;
    text-decoration: none;
    padding: 0.8em 1em calc(0.8em + 3px);
    border-radius: 3px;
    background: rgb(64, 199, 129);
    box-shadow: 0 -3px rgb(53, 167, 110) inset;
    transition: 0.2s;
}
div.publishBtn:hover {
    background: rgb(53, 167, 110);
}
div.publishBtn:active {
    background: rgb(33, 147, 90);
    box-shadow: 0 3px rgb(33, 147, 90) inset;
}
</style>

<script lang="js">
import { Vue, Component } from 'vue-property-decorator'
@Component
export default class CSocialButtonGrid extends Vue {
    publishType = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
}

</script>
