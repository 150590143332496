<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';

@Component({
    name: 'd-sphereTonesBar',
    components: {
        highcharts: Chart
    }
})
export default class DSphereTonesBar extends Vue {
    @Prop({
        required: false,
        default: null
    })
    theme;

    @Prop({
        required: true,
        default: {}
    })
    period;

    @Prop({
        required: true,
        default: []
    })
    datas;

    @Prop({
        required: false,
        default: []
    })
    unrelevants;

    @Prop({
        required: false,
        default: []
    })
    spheres;

    chartOptions = {};

    created() {
        this.$watch('datas', () => {
            this.updateOptions();
        });
    }

    mounted() {
        this.updateOptions();
    }

    updateOptions() {
        const colors = ['#f8cbad', '#bfbfbf', '#3b7cb7', '#e37979', '#bdd7ee'];
        const categories = new Set();
        const mapSeries = new Map();
        let datas = {};
        const series = [];
        const negative = [];
        const neutral = [];
        const positive = [];

        this.datas.forEach((item) => {
            if (this.unrelevants.indexOf(item.id) === -1) {
                for (const sph of item.spheres) {
                    datas = this.mapValue(mapSeries, sph);

                    switch (item.tone) {
                        case -2:
                            datas.neg++;
                            break;
                        case 1:
                            datas.neut++;
                            break;
                        case 2:
                            datas.pos++;
                            break;
                        default:
                            break;
                    }
                    datas.tot++;
                    mapSeries.set(sph, datas);
                }
            }
        });

        const entries = Array.from(mapSeries, ([name, value]) => ({ name, value }));
        const sortMap = entries.sort(this.sortDownByField('value'));

        sortMap.forEach((item) => {
            if (item.value.tot > 0) {
                for (const sph of this.spheres) {
                    if (sph.value === item.name) {
                        categories.add(sph.text);
                    }
                }

                positive.push(item.value.pos);
                neutral.push(item.value.neut);
                negative.push(item.value.neg);
            }
        });

        series.push({ name: 'Негативная', data: negative });
        series.push({ name: 'Нейтральная', data: neutral });
        series.push({ name: 'Позитивная', data: positive });

        this.chartOptions = {
            colors,
            chart: {
                type: 'bar',
                height: 600
            },
            title: {
                text: 'Количество публикаций в разрезе сфер',
                style: {
                    color: '#5e5e5e',
                    fontSize: '1rem'
                }
            },
            xAxis: {
                categories: Array.from(categories),
                labels: {
                    style: {
                        fontSize: '1rem',
                        color: '#5e5e5e'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            legend: {
                itemStyle: {
                    color: '#5e5e5e',
                    fontSize: '.9rem',
                    fontWeight: 'normal'
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        backgroundColor: '#fff',
                        borderColor: '#f2f2f2',
                        borderWidth: '1px',
                        formatter() {
                            return (this.y > 0 ? '<span style="color: ' + this.point.color + '">' + this.y + '</span>' : '');
                        },
                        style: {
                            fontSize: '.8rem',
                            fontWeight: 'bold',
                            color: '#fff',
                            textOutline: '0'
                        }
                    }
                },
                series: {
                    stacking: 'normal',
                    pointWidth: 20
                }
            },
            series: series,
            credits: {
                enabled: false
            }
        };
    }

    mapValue(map, autor) {
        let datas = { 'tot': 0, 'neg': 0, 'neut': 0, 'pos': 0 };
        if (map.size === 0) {
            map = new Map();
            map.set(autor, datas);
        } else {
            if (map.get(autor) === undefined) {
                map.set(autor, datas);
            } else {
                datas = map.get(autor);
            }
        }
        return datas;
    }

    sortDownByField(field) {
        return (a, b) => (a[field].tot < b[field].tot ? 1 : -1);
    }
}
</script>