<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';

@Component({
    name: 'c-netsLine',
    components: {
        highcharts: Chart
    }
})
export default class CSocialNetsLine extends Vue {
    @Prop({
        required: false,
        default: null
    })
    selectPeriod;

    @Prop({
        required: false,
        default: null
    })
    theme;

    @Prop({
        required: true,
        default: {}
    })
    period;

    @Prop({
        required: true,
        default: []
    })
    datas;

    @Prop({
        required: false,
        default: []
    })
    unrelevants;

    chartOptions = {};
    month = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

    created() {
        this.$watch('datas', () => {
            this.updateOptions();
        });
    }

    mounted() {
        this.updateOptions();
    }

    updateOptions() {
        const colors = ['#1f4e79', '#f8cbad', '#bfbfbf', '#3b7cb7', '#e37979', '#bdd7ee'];
        const series = [];
        const categories = new Set();
        let total = new Map();
        let instagram = new Map();
        let facebook = new Map();
        let vkontakte = new Map();
        let youtube = new Map();
        let telegram = new Map();

        let dt = new Date();
        let date = null;
        let valPeriod = null;

        valPeriod = this.period.value;
        if ((this.period.value === null) && (this.period.selectPeriod)) {
            valPeriod = Math.round((this.selectPeriod[1] - this.selectPeriod[0]) / 86400000);
        }

        this.datas.forEach((item) => {
            if (this.unrelevants.indexOf(item.id) === -1) {
                if (valPeriod === 1) {
                    if ((date !== new Date(item.bDate).toLocaleDateString('en-GB'))
            || (dt === (new Date(item.bDate).toLocaleDateString('en-GB') + ' ' + new Date(item.bDate).getHours() + ':00'))) {
                        dt = new Date(item.bDate).toLocaleDateString('en-GB') + ' ' + new Date(item.bDate).getHours() + ':00';
                    } else {
                        dt = new Date(item.bDate).getHours() + ':00';
                    }
                }
                if ((valPeriod > 1) && (valPeriod < 31)) {
                    dt = new Date(item.bDate).toLocaleDateString('en-GB');
                }
                if ((valPeriod > 30) || (valPeriod === null)) {
                    dt = this.month[new Date(item.bDate).getMonth()];
                }
                date = new Date(item.bDate).toLocaleDateString('en-GB');
                categories.add(dt);

                switch (item.socialNet) {
                    case 1:
                        instagram = this.mapValue(instagram, dt);
                        break;
                    case 2:
                        facebook = this.mapValue(facebook, dt);
                        break;
                    case 3:
                        vkontakte = this.mapValue(vkontakte, dt);
                        break;
                    case 4:
                        youtube = this.mapValue(youtube, dt);
                        break;
                    case 5:
                        telegram = this.mapValue(telegram, dt);
                        break;
                    default:
                        break;
                }
                total = this.mapValue(total, dt);
            }
        });

        const totalDatas = [];
        const instagramDatas = [];
        const facebookDatas = [];
        const vkontakteDatas = [];
        const youtubeDatas = [];
        const telegramDatas = [];
        for (const c of categories) {
            totalDatas.push(total.get(c) === undefined ? 0 : total.get(c));
            instagramDatas.push(instagram.get(c) === undefined ? 0 : instagram.get(c));
            facebookDatas.push(facebook.get(c) === undefined ? 0 : facebook.get(c));
            vkontakteDatas.push(vkontakte.get(c) === undefined ? 0 : vkontakte.get(c));
            youtubeDatas.push(youtube.get(c) === undefined ? 0 : youtube.get(c));
            telegramDatas.push(telegram.get(c) === undefined ? 0 : telegram.get(c));
        }
        series.push({ name: 'Всего', data: totalDatas });
        series.push({ name: 'instagram', data: instagramDatas });
        series.push({ name: 'facebook', data: facebookDatas });
        series.push({ name: 'vkontakte', data: vkontakteDatas });
        series.push({ name: 'youtube', data: youtubeDatas });
        series.push({ name: 'telegram', data: telegramDatas });

        this.chartOptions = {
            colors,
            chart: {
                type: 'line'
            },
            title: {
                text: 'Социальные сети',
                style: {
                    color: '#5e5e5e',
                    fontSize: '1rem'
                }
            },
            xAxis: {
                gridLineWidth: 0,
                labels: {
                    autoRotation: [90],
                    style: {
                        color: '#5e5e5e',
                        fontSize: '.9rem'
                    }
                },
                tickWidth: 0,
                tickInterval: ((valPeriod === null) ? 2 : 1),
                categories: Array.from(categories)
            },
            yAxis: {
                title: '',
                labels: {
                    style: {
                        color: '#5e5e5e',
                        fontSize: '.9rem'
                    }
                }
            },
            legend: {
                itemStyle: {
                    color: '#5e5e5e',
                    fontSize: '.9rem',
                    fontWeight: 'normal'
                }
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    showInLegend: true
                },
                series: {
                    dataLabels: {
                        formatter() {
                            return '<span style="color: ' + this.point.color + '">' + this.y + '</span>';
                        },
                        style: {
                            fontSize: '.8rem',
                            fontWeight: 'normal',
                            textOutline: '0px'
                        }
                    }
                }
            },
            series: series,
            credits: {
                enabled: false
            }
        };
    }

    mapValue(map, date) {
        if (map.size === 0) {
            map = new Map();
            map.set(date, 1);
        } else {
            if (map.get(date) === undefined) {
                map.set(date, 1);
            } else {
                let val = map.get(date);
                val++;
                map.set(date, val);
            }
        }
        return map;
    }
}
</script>