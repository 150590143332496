<template>
    <div>
        <div style="display: table; width: 100%;">
            <div style="display:table-header-group;">
                <div class="socialrow row-header">
                    <div class="socialCell"></div>
                    <div class="socialCell">Соцсеть</div>
                    <div class="socialCell">Сообщений</div>
                    <div class="socialCell" title="Удельный вес">%</div>
                    <div class="socialCell" title="Позитивная тональность"><span class="mdi mdi-circle tonePos"></span></div>
                    <div class="socialCell" title="Нейтральная тональность"><span class="mdi mdi-circle toneNeut"></span></div>
                    <div class="socialCell" title="Негативная тональность"><span class="mdi mdi-circle toneNeg"></span></div>
                </div>
            </div>
            <div style="display: table-row-group;">
                <div
                    class="socialrow"
                    v-for="(el, el_indx) in socNetData"
                    :key="'socNetData_indx'+el_indx"
                >
                    <div class="socialCell" style="width: 40px">{{el_indx+1}}</div>
                    <div class="socialCell" style="width: 70px">
                        <c-socSrcIcon :source="el.socialNet"></c-socSrcIcon>
                    </div>
                    <div class="socialCell">{{el.sum}}</div>
                    <div class="socialCell">{{el.percent}}</div>
                    <div class="socialCell tonePos">{{el.pos}}</div>
                    <div class="socialCell toneNeut">{{el.neut}}</div>
                    <div class="socialCell toneNeg">{{el.neg}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
div.socialCell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    border-right: 1px solid #c8c8c8;
    border-bottom: 2px solid #ddd;

    line-height: 1rem;
    font-size: 1.1rem;
    color: #7f7f7f;

    &:last-child {
        border-right: none;
    }
}
div.socialrow {
    display: table-row;
    margin-top: 10px;
}

.row-header {
  text-align: center;
  font-size: 1.1rem;
  color: #7f7f7f;

  & .socialCell {
    padding: 0.3rem;

    &:hover {
      background-color: #f2f2f2;
    }
  }
}

</style>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import CSocialSourceIcon from '@/modules/socialnet/components/cSocialSourceIcon.vue'

@Component({
  name: 'c-socAnalSocNet',
  components: {
    'c-socSrcIcon': CSocialSourceIcon
  }
})
export default class CSocAnalSocNet extends Vue {
    @Prop({
      required: true,
      default: []
    })
    data;

    get socNetData () {
      const data = this.data.slice()
      return data.sort(function (d1, d2) { return (d2.sum - d1.sum) })
    }
}
</script>
