<template>
    <div>
        <template v-if="source!=null && source<=socialNetArr.length">
         <div v-bind:class="['source', socialNetArr[source-1].class]" :title="socialNetArr[source-1].name">
         </div>
         <label v-if="name">
                 {{socialNetArr[source-1].name}}
        </label>
        </template>
    </div>
</template>
<script lang="js">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'c-socSrcIcon'
})
export default class CSocialSourceIcon extends Vue {
    @Prop({
      required: false,
      default: null
    })
    source;

    @Prop({
      required: false,
      default: false
    })
    name;

    socialNetArr = [{name: 'Instagram', class: 'insta'}, {name: 'Facebook', class: 'facebook'}, {name: 'VKontakte', class: 'vk'}, {name: 'YouTube', class: 'youtube'}, {name: 'Telegram', class: 'telegram'}]
}

</script>
