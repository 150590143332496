<template>
  <div>
    <div style="display: table; width: 100%; border: none; margin-bottom: 1rem;">
      <div style="display: table-header-group;">
        <div class="socialrow row-header">
          <div class="socialCell"></div>
          <div class="socialCell">Соцсеть</div>
          <div class="socialCell"><template v-if="isGroup">Сообщества</template><template v-else>Авторы</template></div>
          <div title="Сумма публикаций"
            class="socialCell sortCellHead"
            :class="{'sorted': sortField === 'sum'}"
            @click="clkSort('sum')">Публикации
            <template v-if="sortField=='sum'">
              <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
              <span class="mdi mdi-arrow-down" v-else></span>
            </template>
          </div>
          <div class="socialCell sortCellHead" style="width: 55px"  :class="{'sorted': sortField === 'pos'}"
            @click="clkSort('pos')">
            <span title="Позитивная тональность" class="mdi mdi-circle tonePos"></span>
            <template v-if="sortField=='pos'">
              <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
              <span class="mdi mdi-arrow-down" v-else></span>
            </template>
          </div>
          <div class="socialCell sortCellHead" style="width: 55px" :class="{'sorted': sortField === 'neg'}"
            @click="clkSort('neg')">
            <span title="Негативная тональность" class="mdi mdi-circle toneNeg"></span>
            <template v-if="sortField=='neg'">
              <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
              <span class="mdi mdi-arrow-down" v-else></span>
            </template>
          </div>
          <div title="Количество подписчиков и друзей"
            class="socialCell sortCellHead"
            :class="{'sorted': sortField === 'folowers'}"
            @click="clkSort('folowers')">Аудитория
            <template v-if="sortField=='folowers'">
              <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
              <span class="mdi mdi-arrow-down" v-else></span>
            </template>
          </div>
          <div title="Сумма лайков, комментариев, репостов"
            class="socialCell sortCellHead"
            :class="{'sorted': sortField === 'involvemed'}"
            @click="clkSort('involvemed')">Вовлечённость
            <template v-if="sortField=='involvemed'">
              <span class="mdi mdi-arrow-up" v-if="sortIncrease"></span>
              <span class="mdi mdi-arrow-down" v-else></span>
              </template>
          </div>
        </div>
      </div>
      <div style="display: table-row-group;">
        <div
          class="socialrow"
          v-for="(el, el_indx) in tabDataToPage"
          :key="'analAutor_indx'+el_indx"
        >
          <div class="socialCell" style="text-align: center;">{{el.num}}</div>
          <div class="socialCell">
            <c-socSrcIcon :source="el.socialNet"></c-socSrcIcon>
          </div>
          <div class="socialCell">{{el.autor}}</div>
          <div class="socialCell">{{el.sum}}</div>
          <div class="socialCell tonePos" style="text-align: center;">{{el.pos}}</div>
          <div class="socialCell toneNeg" style="text-align: center;">{{el.neg}}</div>
          <div class="socialCell">
            <template v-if="el.folowers>-1">{{el.folowers}}</template>
          </div>
          <div class="socialCell">{{el.involvemed}}</div>
        </div>
      </div>
    </div>
    <div
      v-if="pgRows>perPage"
      style="width: 100%; display: flex; justify-content:center; align-items: center;"
    >
      <b-pagination v-model="curPage" :per-page="perPage" :total-rows="pgRows" :limit="7" />
    </div>
  </div>
</template>

<style lang="scss" scoped>

div.socialrow {
  display: table-row;
}

div.sortCellHead {
  cursor: pointer;
  vertical-align: middle;
}

.row-header {
  text-align: center;
  font-size: 1.1rem;
  color: #7f7f7f;

  & .socialCell {
    padding: 0.3rem;

    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.sorted {
  background-color: #deebf7;
}

div.socialCell {
  display: table-cell;
  border-right: 1px solid #c8c8c8;
  border-bottom: 2px solid #ddd;
  vertical-align: middle;
  padding: 0 0.3rem;

  line-height: 1rem;
  font-size: 1.1rem;
  color: #7f7f7f;

  &:last-child {
    border-right: none;
  }
}
</style>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import CSocialSourceIcon from '@/modules/socialnet/components/cSocialSourceIcon.vue'

@Component({
  name: 'c-socAnalAutor',
  components: {
    'c-socSrcIcon': CSocialSourceIcon
  }
})
export default class CSocAnalAutor extends Vue {
    @Prop({
      required: false,
      default: []
    })
    data;

    @Prop({
      required: false,
      default: false
    })
    isGroup;

    // ----------------сортировка
    sortField = 'sum'; // поле для сортировки
    sortIncrease = false; // сортировка по возрастанию - true
    // --------------

    pgRows = 1;
    curPage = 1;
    perPage = 20;

    get autorData () {
      this.pgRows = this.data.length
      return this.data.slice()
    }

    get sortedData () {
      const that = this
      let data = that.autorData.slice()
      if (that.sortField !== null) {
        if (that.sortIncrease) {
          data = that.data.sort(function (d1, d2) {
            return (d1[that.sortField] - d2[that.sortField])
          })
        } else {
          data = that.data.sort(function (d1, d2) {
            return (d2[that.sortField] - d1[that.sortField])
          })
        }
      }
      return data
    }

    // массив элементов с текущей страницы
    get tabDataToPage () {
      if (this.sortedData === undefined) {
        return []
      }
      const sorted = this.sortedData.slice()
      let begIndx = (this.curPage - 1) * this.perPage
      if (begIndx >= this.data.length) { begIndx = sorted.length - 1 }
      const endIndx = begIndx + this.perPage
      let dat = []
      if (endIndx >= sorted.length) {
        dat = sorted.slice(begIndx)
      } else {
        dat = sorted.slice(begIndx, endIndx)
      }
      for (let i = 0; i < dat.length; i++) {
        dat[i].num = begIndx + i + 1
      }
      return dat
    }

    clkSort (field) { // нажать на кнопку сортировать
      if (this.sortField === field) {
        this.sortIncrease = !this.sortIncrease
        return
      }
      this.sortField = field
    }
}
</script>
