<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';

@Component({
    name: 'd-groupsBar',
    components: {
        highcharts: Chart
    }
})
export default class DGroupsBar extends Vue {
    @Prop({
        required: false,
        default: null
    })
    theme;

    @Prop({
        required: true,
        default: {}
    })
    period;

    @Prop({
        required: true,
        default: []
    })
    datas;

    chartOptions = {};

    created() {
        this.$watch('datas', () => {
            this.updateOptions();
        });
    }

    mounted() {
        this.updateOptions();
    }

    updateOptions() {
        const colors = ['#bdd7ee'];
        const categories = new Set();

        const mapSeries = new Map();
        const series = [];

        this.datas.forEach((item) => {
            if (item.isGroup) {
                if ((item.folowers !== null) && (item.folowers > 0)) {
                    mapSeries.set(item.autor, item.folowers);
                }
            }
        });

        const entries = Array.from(mapSeries, ([name, value]) => ({ name, value }));
        const sortMap = entries.sort(this.sortDownByField('value'));

        let i = 0;
        sortMap.forEach((item) => {
            if (i < 10) {
                categories.add(item.name);
                series.push(item.value);
            }
            i++;
        });

        this.chartOptions = {
            colors,
            chart: {
                type: 'bar'
            },
            title: {
                text: 'Влиятельность авторов',
                style: {
                    color: '#5e5e5e'
                }
            },
            xAxis: {
                categories: Array.from(categories),
                labels: {
                    style: {
                        fontSize: '1rem',
                        color: '#5e5e5e'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            legend: {
                itemStyle: {
                    color: '#5e5e5e',
                    fontSize: '.9rem',
                    fontWeight: 'normal'
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        align: 'right',
                        enabled: true,
                        style: {
                            fontSize: '.8rem',
                            fontWeight: 'bold',
                            color: '#5e5e5e',
                            textOutline: '0'
                        },
                        verticalAlign: 'top',
                        y: -3
                    }
                },
                series: {
                    stacking: 'normal',
                    pointWidth: 20
                }
            },
            series: { name: 'Количество подписчиков', data: series },
            credits: {
                enabled: false
            }
        };
    }

    sortDownByField(field) {
        return (a, b) => (a[field] < b[field] ? 1 : -1);
    }
}
</script>