<template>
  <div class="chart-block">
    <template v-if="main">
      <div class="row" style="justify-content: space-evenly; margin-bottom: 2rem">
        <div>
          <a href="#" @click="test">Хронология пуликаций</a>
          <d-toneLine v-if="(themeData.datas !== null || themeData.datas.length !== 0)"
            :selectPeriod="selectPeriod"
            :theme="themeData.theme"
            :period="themeData.period"
            :datas="themeData.datas"
            :unrelevants="unrelArray"
          >
          </d-toneLine>
        </div>
        <div>
          <a href="#" @click="test">Публикации по сферам жизнедеятельности</a>
          <d-sphereTonesSpider v-if="themeData.datas !== null || themeData.datas.length !== 0"
            :theme="themeData.theme"
            :period="themeData.period"
            :datas="themeData.datas"
            :unrelevants="unrelArray"
            :spheres="spheres"
          >
          </d-sphereTonesSpider>
        </div>
      </div>
      <div class="row" style="justify-content: space-evenly;">
        <div>
          <a href="#" @click="test">Топ авторов</a>
          <d-autorTonesBar v-if="themeData.datas !== null || themeData.datas.length !== 0"
            :theme="themeData.theme"
            :period="themeData.period"
            :datas="themeData.datas"
            :unrelevants="unrelArray"
          >
          </d-autorTonesBar>
        </div>
        <div>
          <a href="#" @click="test">Топ событий по количеству публикаций</a>
          <d-eventTonesBar v-if="themeData.datas !== null || themeData.datas.length !== 0"
            :theme="themeData.theme"
            :period="themeData.period"
            :datas="themeData.datas"
            :unrelevants="unrelArray"
          >
          </d-eventTonesBar>
        </div>
      </div>
    </template>
    <template v-else>
      <b-tabs>
        <b-tab title="Тональность" active @click="chart = true">
          <div class="diagram">
            <a v-if="$route.name==='Topic'" title="Сравнить темы"
              class="compare" :class="{'active': compare}"
              @click="compare = !compare"></a>
            <template v-if="!compare">
            <d-toneLine v-if="(themeData.datas !== null || themeData.datas.length !== 0)"
              :selectPeriod="selectPeriod"
              :theme="themeData.theme"
              :period="themeData.period"
              :datas="themeData.datas"
              :unrelevants="unrelArray"
            >
            </d-toneLine>
            <d-sphereTonesBar v-if="themeData.datas !== null || themeData.datas.length !== 0"
              :theme="themeData.theme"
              :period="themeData.period"
              :datas="themeData.datas"
              :unrelevants="unrelArray"
              :spheres="spheres"
            >
            </d-sphereTonesBar>
            <d-sphereTonesSpider v-if="themeData.datas !== null || themeData.datas.length !== 0"
              :theme="themeData.theme"
              :period="themeData.period"
              :datas="themeData.datas"
              :unrelevants="unrelArray"
              :spheres="spheres"
            >
            </d-sphereTonesSpider>
            </template>
            <template v-else>
            <d-tonesBar v-if="(sectionData.section !== null) && (sectionData.datas !== undefined) && (sectionData.datas !== null) && (sectionData.datas.length !== 0)"
              :section="sectionData.section"
              :datas="sectionData.datas"
              :unrelevants="unrelArray"
            >
            </d-tonesBar>
          </template>
          </div>
        </b-tab>
        <b-tab title="Социальные сети" @click="chart = true">
          <div class="diagram">
            <a v-if="$route.name==='Topic'" title="Сравнить темы"
              class="compare" :class="{'active': compare}"
              @click="compare = !compare"></a>
            <a class="chart" :class="{'active': chart}" @click="chart = !chart"></a>
            <a class="grid" :class="{'active': grid}" @click="grid = !grid"></a>
            <template v-if="chart">
            <d-netsLine v-if="(themeData.datas !== null || themeData.datas.length !== 0)"
              :selectPeriod="selectPeriod"
              :theme="themeData.theme"
              :period="themeData.period"
              :datas="themeData.datas"
              :unrelevants="unrelArray"
            >
            </d-netsLine>
            <d-netsPie v-if="(themeData.datas !== null || themeData.datas.length !== 0)"
              :theme="themeData.theme"
              :period="themeData.period"
              :datas="themeData.datas"
              :unrelevants="unrelArray"
            >
            </d-netsPie>
            </template>
            <template v-if="compare">
            <d-netsBar v-if="(sectionData.section !== null) && (sectionData.datas !== undefined) && (sectionData.datas !== null) && (sectionData.datas.length !== 0)"
              :section="sectionData.section"
              :datas="sectionData.datas"
              :unrelevants="unrelArray"
            >
            </d-netsBar>
            </template>
            <template v-if="grid">
              <c-socAnalSocNet v-if="(socAnalData !==  null) && (socAnalData.length !== 0)" :data="socAnalData"></c-socAnalSocNet>
            </template>
          </div>
        </b-tab>
        <b-tab title="Авторы" @click="chart = true">
          <div class="diagram">
            <a class="chart" :class="{'active': chart}" @click="chart = !chart"></a>
            <a class="grid" :class="{'active': grid}" @click="grid = !grid"></a>
          <template v-if="chart">
            <d-autorTonesBar v-if="themeData.datas !== null || themeData.datas.length !== 0"
              :theme="themeData.theme"
              :period="themeData.period"
              :datas="themeData.datas"
              :unrelevants="unrelArray"
            >
            </d-autorTonesBar>
            <d-autorsBar v-if="themeData.datas !== null || themeData.datas.length !== 0"
              :theme="themeData.theme"
              :period="themeData.period"
              :datas="themeData.datas"
            >
            </d-autorsBar>
          </template>
          <template v-if="grid">
            <c-socAnalAutor v-if="(autorUserAnalData !==  null) && (autorUserAnalData.length !== 0)" :data="autorUserAnalData"></c-socAnalAutor>
          </template>
          </div>
        </b-tab>
        <b-tab title="Сообщества" @click="chart = true">
          <div class="diagram">
            <a class="chart" :class="{'active': !grid}" @click="grid = !grid"></a>
            <a class="grid" :class="{'active': grid}" @click="grid = !grid"></a>
            <template v-if="!grid">
            <d-groupTonesBar v-if="themeData.datas !== null || themeData.datas.length !== 0"
              :theme="themeData.theme"
              :period="themeData.period"
              :datas="themeData.datas"
              :unrelevants="unrelArray"
            >
            </d-groupTonesBar>
            <d-groupsBar v-if="themeData.datas !== null || themeData.datas.length !== 0"
              :theme="themeData.theme"
              :period="themeData.period"
              :datas="themeData.datas"
            >
            </d-groupsBar>
            </template>
            <template v-else>
            <c-socAnalAutor v-if="(autorGroupAnalData !==  null) && (autorGroupAnalData.length !== 0)" :data="autorGroupAnalData" isGroup="true"></c-socAnalAutor>
            </template>
          </div>
        </b-tab>
        <b-tab title="Язык" @click="chart = true">
          <div class="diagram">
            <a v-if="$route.name==='Topic'" title="Сравнить темы"
              class="compare" :class="{'active': compare}"
              @click="compare = !compare"></a>
            <template v-if="!compare">
            <d-langLine v-if="(themeData.datas !== null && themeData.datas.length !== 0)"
              :selectPeriod="selectPeriod"
              :theme="themeData.theme"
              :period="themeData.period"
              :datas="themeData.datas"
              :unrelevants="unrelArray"
            >
            </d-langLine>
            <d-langPie v-if="(themeData.datas !== null && themeData.datas.length !== 0)"
              :theme="themeData.theme"
              :period="themeData.period"
              :datas="themeData.datas"
              :unrelevants="unrelArray"
            >
            </d-langPie>
            </template>
            <template v-else>
            <d-langsBar v-if="(sectionData.section !== null) && (sectionData.datas !== undefined) && (sectionData.datas !== null) && (sectionData.datas.length !== 0)"
              :section="sectionData.section"
              :datas="sectionData.datas"
              :unrelevants="unrelArray"
            >
            </d-langsBar>
            </template>
          </div>
        </b-tab>
        <b-tab title="События" @click="chart = true">
          <div class="diagram">
            <d-eventTonesBar v-if="themeData.datas !== null || themeData.datas.length !== 0"
              :theme="themeData.theme"
              :period="themeData.period"
              :datas="themeData.datas"
              :unrelevants="unrelArray"
            >
            </d-eventTonesBar>
          </div>
        </b-tab>
      </b-tabs>
    </template>
  </div>
</template>

<script lang="js">
import { Vue, Prop, Component } from 'vue-property-decorator'

import cSocAnalAutor from '@/modules/socialnet/components/cSocAnalAutor.vue'
import cSocAnalSocNet from '@/modules/socialnet/components/cSocAnalSocNet.vue'
import dSocialToneLine from '@/modules/socialnet/components/dSocialToneLine.vue'
import dSocialTonesBar from '@/modules/socialnet/components/dSocialTonesBar.vue'
import dSocialNetsLine from '@/modules/socialnet/components/dSocialNetsLine.vue'
import dSocialNetsPie from '@/modules/socialnet/components/dSocialNetsPie.vue'
import dSocialAutorTonesBar from '@/modules/socialnet/components/dSocialAutorTonesBar.vue'
import dSocialAutorsBar from '@/modules/socialnet/components/dSocialAutorsBar.vue'
import dSocialLangsBar from '@/modules/socialnet/components/dSocialLangsBar.vue'
import dSocialNetsBar from '@/modules/socialnet/components/dSocialNetsBar.vue'
import dMediaLangLine from '@/modules/socialnet/components/dMediaLangLine.vue'
import dMediaLangPie from '@/modules/socialnet/components/dMediaLangPie.vue'
import dEventTonesBar from '@/modules/socialnet/components/dEventTonesBar.vue'
import dGroupTonesBar from '@/modules/socialnet/components/dGroupTonesBar.vue'
import dGroupsBar from '@/modules/socialnet/components/dGroupsBar.vue'
import dSphereTonesBar from '@/modules/socialnet/components/dSphereTonesBar.vue'
import dSphereTonesSpider from '@/modules/socialnet/components/dSphereTonesSpider.vue'

import bootstrap from 'bootstrap-vue'

Vue.use(bootstrap)

@Component({
  name: 'c-socCharts',
  components: {
    'c-socAnalAutor': cSocAnalAutor,
    'c-socAnalSocNet': cSocAnalSocNet,
    'd-toneLine': dSocialToneLine,
    'd-tonesBar': dSocialTonesBar,
    'd-netsLine': dSocialNetsLine,
    'd-netsPie': dSocialNetsPie,
    'd-autorTonesBar': dSocialAutorTonesBar,
    'd-autorsBar': dSocialAutorsBar,
    'd-langsBar': dSocialLangsBar,
    'd-netsBar': dSocialNetsBar,
    'd-langLine': dMediaLangLine,
    'd-langPie': dMediaLangPie,
    'd-eventTonesBar': dEventTonesBar,
    'd-groupTonesBar': dGroupTonesBar,
    'd-groupsBar': dGroupsBar,
    'd-sphereTonesBar': dSphereTonesBar,
    'd-sphereTonesSpider': dSphereTonesSpider
  }
})
export default class CSocialCharts extends Vue {
  @Prop({
    required: false,
    default: false
  })
  main

  @Prop({
    required: false,
    default: null
  })
  sectionData

  @Prop({
    required: false,
    default: {theme: null, period: {}, datas: []}
  })
  themeData

  @Prop({
    required: false,
    default: null
  })
  selectPeriod

  @Prop({
    required: false,
    default: null
  })
  unrelevants // нерелевантные данные

  @Prop({
    required: false,
    default: null
  })
  spheres

  @Prop({
    required: false,
    default: null
  })
  autorUserAnalData

  @Prop({
    required: false,
    default: null
  })
  autorGroupAnalData

  @Prop({
    required: false,
    default: null
  })
  socAnalData

  @Prop({
    required: false,
    default: null
  })
  tone

  unrelArray = []

  compare = false
  chart = true
  grid = false

  created () {
    this.$watch('compare', () => {
      if (this.compare) {
        this.grid = false
        this.chart = false
      }
    })

    this.$watch('chart', () => {
      if (this.chart) {
        this.compare = false
        this.grid = false
      }
    })

    this.$watch('grid', () => {
      if (this.grid) {
        this.compare = false
        this.chart = false
      }
    })
  }

  mounted () {
    if (this.themeData.datas !== null || this.themeData.datas.length !== 0) {
      this.themeData.datas.sort(this.sortByField('bDate'))
    }

    if (this.unrelevants !== null) {
      for (const e of this.unrelevants) {
        this.unrelArray.push(e.id)
      }
    }
  }

  sortByField (field) {
    return (a, b) => (a[field] > b[field] ? 1 : -1)
  }

  test () {
    this.$router.push({path: '/topic',
      query: {theme: this.themeData.theme,
        period: this.themeData.period,
        tone: this.tone,
        periodFrom: (this.selectPeriod !== null ? this.selectPeriod[0].getTime() : null),
        periodTo: (this.selectPeriod !== null ? this.selectPeriod[1].getTime() : null)}
    })
  }
}
</script>

<style lang="scss" scoped>
.row {
  & > div {
    width: 40%;
    text-align: center;

    & > a {
       color: #5e5e5e;
       font-size: 1.1rem;
       display: block;
       margin-bottom: 1rem;
    }
  }
}
</style>
