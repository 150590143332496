<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Chart } from 'highcharts-vue'

@Component({
  name: 'd-netsPie',
  components: {
    highcharts: Chart
  }
})
export default class DSocialPie extends Vue {
  @Prop({
    required: false,
    default: null
  })
  theme

  @Prop({
    required: true,
    default: {}
  })
  period

  @Prop({
    required: true,
    default: []
  })
  datas

  @Prop({
    required: false,
    default: []
  })
  unrelevants

  chartOptions = {}

  created () {
    this.$watch('datas', () => {
      if (this.datas.length !== 0) {
        this.updateOptions()
      }
    })
  }

  mounted () {
    this.updateOptions()
  }

  updateOptions () {
    const colors = ['#f8cbad', '#bfbfbf', '#3b7cb7', '#e37979', '#bdd7ee', '#1f4e79']
    const series = []
    let instagram = 0
    let facebook = 0
    let vkontakte = 0
    let youtube = 0
    let telegram = 0

    this.datas.forEach((item) => {
      if (this.unrelevants.indexOf(item.id) === -1) {
        switch (item.socialNet) {
          case 1:
            instagram++
            break
          case 2:
            facebook++
            break
          case 3:
            vkontakte++
            break
          case 4:
            youtube++
            break
          case 5:
            telegram++
            break
          default:
            break
        }
      }
    })

    series.push({name: 'instagram', y: instagram})
    series.push({name: 'facebook', y: facebook})
    series.push({name: 'vkontakte', y: vkontakte})
    series.push({name: 'youtube', y: youtube})
    series.push({name: 'telegram', y: telegram})

    this.chartOptions = {
      colors,
      chart: {
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{series.name} <b>{point.y}</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            alignTo: 'connectors',
            connectorShape: 'crookedLine',
            crookDistance: '50%',
            enabled: true,
            format: '<span style="color:{point.color}">{point.percentage:.1f}%</span>',
            filter: {
              property: 'percentage',
              operator: '>',
              value: 0
            },
            style: {
              fontSize: '1.1rem',
              fontWeight: 'normal',
              textOutline: '0px'
            }
          },
          showInLegend: true
        }
      },
      legend: {
        itemStyle: {
          color: '#5e5e5e',
          fontSize: '.9rem',
          fontWeight: 'normal'
        }
      },
      series: [{
        type: 'pie',
        name: '',
        data: series
      }],
      credits: {
        enabled: false
      }
    }
  }
}
</script>
